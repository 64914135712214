import styled from "styled-components";


// COLOURS

export const DUBS_GREEN = "#20b766"
export const DUBS_BLACK = "#232323"


// ELEMENTS

export const DubsHeader = styled.h1`
color: ${DUBS_GREEN};
align: center;
text-align:center;
`

export const DubsSubHeader = styled.h3`
color: ${DUBS_GREEN};
align: center;
text-align:center;
`

export const MainTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
  box-sizing:border-box;
`;

export const Text = styled.h1`
font-size: 2rem;
color: ${DUBS_GREEN};
display: flex;
align-items: center;
flex-direction: column;
text-align: center;
margin: 2rem;
`

export const TextWrap = styled.div`
display: flex;
height: 90vh;
width: 100%;
justify-content: center;
align-items: center;
`



export const ButtonWrap = styled.div`
width: 50%;
display: flex;
justify-content: center;
align-items: center;
align: center;
margin: 4%;
`

export const DubsButton = styled.button `
    
`