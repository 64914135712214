import React from 'react';
import styled from 'styled-components';

const Footer = () => (
    <FooterContainer>
        <FooterText>© 2023 Dubs Advising. All rights reserved.</FooterText>
        <SocialLinks>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="https://instagram.com/dubsadvisinginc" target="_blank" rel="noopener noreferrer">Instagram</a>
        </SocialLinks>
    </FooterContainer>
);

const FooterContainer = styled.footer`
    width: 100%;
    background-color: #232323;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
`;

const FooterText = styled.p`
    margin: 0;
`;

const SocialLinks = styled.div`
    & > a {
        margin: 0 10px;
        color: #ffffff;
        text-decoration: none;

        &:hover {
            color: #999999;
        }
    }
`;

export default Footer;
