import Alert from 'react-bootstrap/Alert';

const ErrorMessage = (props: {message: string;}) => {
  return (
    <Alert variant="danger" dismissible style={{position: "absolute", zIndex:"1000", top: "0px", marginTop: "150px", marginBottom: "75px"}}>
      <Alert.Heading>{props.message}</Alert.Heading>
    </Alert>
  );
}

export default ErrorMessage
