import React from "react";
import PropTypes from "prop-types";

const VGK = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 183.67999 248.56848"
    >
      <path
        d="M106.32606,13.60381C103.82159,11.27011,91.86846,0,91.86846,0S79.85839,11.27011,77.35392,13.60381H0V183.45229c28.91523,31.98889,53.10612,47.30032,91.86846,65.11619C130.46,230.7526,154.76476,215.49813,183.68,183.45229V13.60381Z"
        fill="#fff"
      />
      <path
        d="M105.07384,16.79133C104.96,16.67747,91.86846,4.38284,91.86846,4.38284S78.77692,16.62055,78.60615,16.79133H3.18751V182.20007c27.09379,29.99673,51.17085,45.59271,88.68095,62.83938,37.33935-17.24667,61.4733-32.72885,88.624-62.83938V16.79133Z"
        fill="#b4975a"
      />
      <path d="M174.06057,23.16635V179.75254c-24.70317,27.37838-50.54474,43.65743-82.19211,58.2289-31.64737-14.57147-57.54584-30.85052-82.249-58.2289V23.16635h55.326c-21.80026,12.69311-36.144,34.77793-36.144,64.60389v84.24121c14.85605,15.42523,34.94873,30.9074,54.01683,39.9007L40.9253,91.18543,80.37068,110.1397,75.58943,114.864l14.173,45.76347h4.21206l14.1161-45.76347-4.78127-4.72433,39.44539-18.95427-41.836,120.72673c18.89735-8.93638,38.99-24.36164,53.95989-39.9007V87.71332c0-29.769-14.28684-51.85387-36.08709-64.547Z" />
      <path
        d="M102.79705,22.76789c1.13839-4.83818,2.27678-5.97656,2.27678-5.97656S93.40529,5.86273,91.98229,4.49668l-.11383-.11386V125.10957l8.76563-28.23217L147.3652,74.39409C143.21006,50.20322,127.44329,31.76122,102.79705,22.76789Z"
        fill="#333f42"
      />
      <path
        d="M151.6911,99.83722l-34.778,100.691,6.03349-7.798s15.82369-12.18082,25.557-23.3371V117.9377Z"
        fill="#333f42"
      />
      <polygon
        points="79.232 115.775 80.371 110.14 75.589 114.864 89.762 160.628 91.868 156.586 79.232 115.775"
        fill="#fff"
      />
      <path
        d="M79.91531,91.58386,42.63288,77.46777,83.10281,96.8774V37.73778ZM31.9889,99.83722,60.961,183.62307,44.85274,145.6576,35.1764,117.9377Z"
        fill="#333f42"
      />
      <polygon
        points="142.755 91.185 100.919 211.912 106.497 205.423 146.853 89.25 142.755 91.185"
        fill="#fff"
      />
      <path
        d="M80.88295,22.76789a28.58092,28.58092,0,0,0-1.13839-3.75669,6.32642,6.32642,0,0,0-1.13841-2.21987C49.29249,27.49224,28.80139,52.08155,28.80139,87.77024v84.24121c1.02455,1.08146,2.106,2.16295,3.18751,3.24441V87.71332c0-29.88288,14.40069-53.95993,45.70654-67.05146A20.0713,20.0713,0,0,1,80.88295,22.76789Z"
        fill="#fff"
      />
    </svg>
  );
};

VGK.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

VGK.defaultProps = {
  size: "100"
};

export default VGK;
