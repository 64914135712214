import React from "react";
import PropTypes from "prop-types";

const LAK = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 134.3968 159.22594"
    >
      <polygon
        points="133.397 0 0 0 0 111.012 1.049 111.792 65.165 158.123 66.698 159.226 68.205 158.123 132.321 111.792 133.37 111.038 133.37 0 133.397 0"
        fill="#111"
      />
      <polygon
        points="2.556 109.989 66.698 156.347 130.814 109.989 130.814 2.852 2.556 2.852 2.556 109.989"
        fill="#fff"
      />
      <path
        d="M6.78015,77.78341H126.56283V7.103H6.78015Zm0,30.05329L66.6984,151.12743l59.86443-43.29074V81.46944H6.78015Z"
        fill="#acaea9"
      />
      <path
        d="M10.57381,72.214H122.79607V10.86977H10.57381Zm0,33.68552,56.12459,40.5733,56.09768-40.57329V88.03436H10.57381Z"
        fill="#111"
      />
      <path
        d="M67.39794,20.5826s3.60532,5.5156,3.60532,20.95928-3.60532,20.95928-3.60532,20.95928H77.78341V46.89605h31.29094V62.50116h10.35856s-3.60532-5.5156-3.60532-20.95928,3.60532-20.95928,3.60532-20.95928ZM24.32245,54.86V41.54188c0-15.44368,3.60532-20.95928,3.60532-20.95928H13.937s3.60532,5.5156,3.60532,20.95928S13.937,62.50116,13.937,62.50116h45.6584a23.60005,23.60005,0,0,0,3.82056-13.076S58.1963,54.86,45.95436,54.86Z"
        fill="#acaea9"
      />
      <path
        d="M23.35386,55.82863V41.54188c0-11.35406,1.91028-17.43468,3.0134-19.99069H15.49749c1.10312,2.556,3.0134,8.63662,3.0134,19.99069s-1.91028,17.43468-3.0134,19.99069H59.08418a23.852,23.852,0,0,0,3.22864-10.03569c-2.50219,1.72193-7.69493,4.33175-16.35846,4.33175ZM68.95845,21.5512c1.10312,2.556,3.0134,8.66353,3.0134,20.01759,0,11.32716-1.91028,17.40777-3.0134,19.96378h7.85637V45.92746h33.22812V61.53257h7.82946c-1.07621-2.556-2.9865-8.63662-2.9865-19.99069s1.91029-17.43468,2.9865-19.99069Z"
        fill="#fff"
      />
      <rect
        x="76.81481"
        y="27.25513"
        width="33.20122"
        height="12.94147"
        fill="#acaea9"
      />
      <rect x="77.78341" y="28.22373" width="31.29095" height="11.0312" />
      <path
        d="M83.54116,127.02023l4.17033-23.86506-.861-.37667-4.0358,5.38107a16.966,16.966,0,0,0-7.64112-5.0044L76.761,99.2808s-2.0179.80716-2.87887,1.15693c.08071-.16143.18834-.34977.18834-.34977s-3.06721.16143-3.55151.18834c-.24215-.43049-1.91028-3.39007-2.15243-3.79366.21524-.34977.83407-1.45289.91478-1.58741.10762-.08071.88788-.75335.88788-.75335l-1.0224-1.93719-2.17933.13453s-.0269,1.0224-.0269,1.21074l-.24215.40358L66.45626,93.55c-.0269-.18834-.05381-1.21074-.05381-1.21074l-2.17933-.13453L63.20071,94.115s.78026.69954.88788.78026c.08071.10762.69954,1.23764.91478,1.56051-.21524.40358-1.91028,3.39007-2.15243,3.79366-.4843,0-3.55151-.18834-3.55151-.18834a2.50834,2.50834,0,0,0,.18834.34977c-.861-.34977-2.87887-1.15693-2.87887-1.15693s1.29146,3.20174,1.58741,3.87437a16.98472,16.98472,0,0,0-7.64112,5.0313c-.64573-.861-4.0358-5.408-4.0358-5.408l-.861.37667,4.17033,23.86506S55.721,128.5,66.6715,128.5C77.622,128.52693,83.54116,127.02023,83.54116,127.02023Z"
        fill="#fff"
      />
      <path
        d="M63.22761,127.42381a40.77337,40.77337,0,0,1-8.63662-1.72194,66.70912,66.70912,0,0,0,9.0133.72644A34.985,34.985,0,0,1,56.286,124.8947a70.63658,70.63658,0,0,0,8.233.61883,35.666,35.666,0,0,1-7.21064-1.50669,44.6562,44.6562,0,0,1,18.753,0,35.666,35.666,0,0,1-7.21064,1.50669,65.59361,65.59361,0,0,0,8.233-.61883,34.986,34.986,0,0,1-7.31826,1.53361,66.33155,66.33155,0,0,0,9.0133-.72644,41.22269,41.22269,0,0,1-8.63662,1.74885,62.96267,62.96267,0,0,0,12.69933-1.426l3.65913-21.28214-3.71294,4.95058c-1.61432-2.66363-4.78915-4.78916-8.85187-5.973l1.13-2.71744-5.73084,2.556v-1.39908l-2.63673-4.547-2.66363,4.547v1.426L58.277,101.00274l1.13,2.74434c-4.06271,1.15693-7.21064,3.30936-8.85186,5.973l-3.686-4.95058,3.65913,21.28215a70.58287,70.58287,0,0,0,12.69933,1.39908Z"
        fill="#111"
      />
      <path
        d="M68.85083,103.88162l-2.15243-2.79816-2.17933,2.79816,2.17933,2.58291Zm.4843.21524v9.60521l4.843-11.75764Zm-5.30036,0-4.843-2.15243,4.843,11.75764Z"
        fill="#acaea9"
      />
      <path
        d="M83.64878,118.59885a2.087,2.087,0,0,1-1.695-2.07171,2.046,2.046,0,0,1,1.991-2.09862,1.04583,1.04583,0,0,1,.40358.05381l1.34527-7.85637-5.86536,7.85636c.13453,3.73985-.78026,5.27345-.78026,5.27345a25.02685,25.02685,0,0,0-4.17033-1.23765l-.75335-3.79366a1.52866,1.52866,0,0,1-.88788-.296h-.0269l-1.93719,3.4977s-2.42148-.21525-3.63223-.21525l1.07621-3.36317-2.0179-5.24654-2.0179,5.21964,1.07621,3.36317c-1.23764,0-3.65913.24215-3.65913.24215l-1.77576-3.22864H59.972a1.38157,1.38157,0,0,1-.67263-.16143l-.80716,3.95509a28.67608,28.67608,0,0,0-4.14341,1.23765s-.91478-1.50669-.80716-5.27345L47.6763,106.626l1.37217,7.82946a1.78163,1.78163,0,0,1,.40358-.0269,2.06365,2.06365,0,0,1,1.991,2.09862,2.113,2.113,0,0,1-1.695,2.07171l1.15693,6.78015a45.15605,45.15605,0,0,1,31.5869,0Z"
        fill="#acaea9"
      />
      <path
        d="M62.824,104.74259a.90143.90143,0,0,0-1.61432.565.95331.95331,0,0,0,.67263.88788,1.007,1.007,0,0,0,.4843.13453.85.85,0,0,0,.861-.83881q.00014-.01107,0-.02216A.82222.82222,0,0,0,62.824,104.74259Zm8.77115,0a.94564.94564,0,0,0-.72644-.34977.90171.90171,0,0,0-.88788.91478.92031.92031,0,0,0,.67263.88788,1.007,1.007,0,0,0,.4843.13453.85.85,0,0,0,.861-.83882q.00014-.01107,0-.02215A.883.883,0,0,0,71.59518,104.74259Zm-3.982-.94169a.943.943,0,0,0-.91478-.91478.92445.92445,0,0,0-.91478.91478.90723.90723,0,0,0,.89962.91478q.00757.00006.01516,0A.92445.92445,0,0,0,67.61318,103.8009Z"
        fill="#111"
      />
      <path
        d="M72.75211,114.21327a1.70574,1.70574,0,0,1-.83407-1.4798,1.66563,1.66563,0,0,1,.5112-1.23764,1.71163,1.71163,0,0,1,1.21074-.5112,1.80122,1.80122,0,0,1,1.39908.67263,1.68266,1.68266,0,0,1,.78026,1.39908,1.65338,1.65338,0,0,1-1.21074,1.58741l.69954,3.47079c.5112.10762.94169.21525,1.37217.32286,1.0224-3.04031,1.4798-7.668-3.55151-10.27785,0,0,7.12992,1.66813,4.95058,10.73523a3.8381,3.8381,0,0,1,.72644.24215c.64573-1.45289.53811-4.843.53811-4.843l.96859-1.23764c-.59192-2.42148-2.34077-4.89678-6.56491-6.4842a10.11516,10.11516,0,0,1,7.37207,5.35417l1.0224-1.34527c-1.426-2.58291-4.57391-4.73534-8.58281-5.91918l-5.13893,12.5648c.861.05381,1.74885.10762,2.58291.18834ZM51.22782,110.608l1.0224,1.34527a10.02692,10.02692,0,0,1,7.37207-5.35417c-4.22414,1.56051-5.973,4.0358-6.56491,6.45728L54.026,114.294s-.13453,3.417.565,4.843a7.62093,7.62093,0,0,1,.72645-.24215c-2.20624-9.06711,4.92368-10.70834,4.92368-10.70834-5.0313,2.58291-4.57391,7.23754-3.55151,10.251.43049-.08071.88788-.18834,1.39908-.32286l.75335-3.76675a1.72322,1.72322,0,0,1,.61883-3.33627,1.63532,1.63532,0,0,1,1.37217.67263,1.61452,1.61452,0,0,1,.78026,1.39908,1.56207,1.56207,0,0,1-.861,1.426l1.61432,2.93269c.861-.08071,1.72194-.13452,2.60982-.18834l-5.16583-12.56481c-4.00891,1.15694-7.12993,3.30937-8.58282,5.91919Z"
        fill="#fff"
      />
      <path
        d="M50.044,116.52713a.63908.63908,0,0,0-.60432-.672l-.01451-.0006c-.05381,0-.13453,0-.16143.0269l.21524,1.29146A.64252.64252,0,0,0,50.044,116.52713Zm25.0758-3.82056a1.46471,1.46471,0,0,0-.43049-1.04931,1.503,1.503,0,1,0,.43049,1.07621Zm-15.65891-1.45288a1.47549,1.47549,0,0,0-1.4798,1.47118v.00862a1.45881,1.45881,0,0,0,1.43752,1.4798q.02113.00031.04227,0a1.49871,1.49871,0,0,0,1.5067-1.4798,1.48155,1.48155,0,0,0-1.48308-1.48Z"
        fill="#fff"
      />
      <path
        d="M83.32592,116.52713a.61652.61652,0,0,0,.565.61883l.21524-1.26456a.34137.34137,0,0,0-.16143-.0269.63906.63906,0,0,0-.61942.65813Zm-33.90076-1.88337a1.81665,1.81665,0,0,0-.37667.05381l.0269.24215a1.57447,1.57447,0,0,1,.34977-.05381,1.64,1.64,0,0,1,.21524,3.22865l.0269.24215a1.85946,1.85946,0,0,0,1.5067-1.85648,1.80513,1.80513,0,0,0-1.74885-1.85646Z"
        fill="#fff"
      />
      <path
        d="M62.09758,106.06094a.74244.74244,0,0,0,.77938-.70355q.00127-.02487.00088-.04979a.76411.76411,0,0,0-.74762-.78026q-.01632-.00035-.03264,0a.77564.77564,0,0,0-.75335.78026.752.752,0,0,0,.75071.75335Zm8.77115,0a.75828.75828,0,0,0,.78-.736l.00031-.01737a.78026.78026,0,0,0-1.56051-.00246v.00246a.75829.75829,0,0,0,.76289.75365Zm-3.417-2.26a.77519.77519,0,0,0-.75335-.78026.79868.79868,0,0,0-.78026.78026.76411.76411,0,0,0,.74762.78026q.01632.00035.03264,0a.74244.74244,0,0,0,.75422-.73047q.00039-.0249-.00088-.04979Zm16.22394,14.5558.05381-.24215a1.6351,1.6351,0,0,1,.21524-3.22865,1.35062,1.35062,0,0,1,.32286.05381l.05381-.21524a1.05625,1.05625,0,0,0-.37667-.08071,1.88035,1.88035,0,0,0-.269,3.71295Z"
        fill="#fff"
      />
      <path
        d="M60.77922,112.73346a1.3204,1.3204,0,1,1,.00023-.02286Z"
        fill="#111"
      />
      <path
        d="M74.95835,112.73346a1.3204,1.3204,0,1,1,.00023-.02286Z"
        fill="#111"
      />
      <path
        d="M66.64459,114.91282a.71637.71637,0,0,0,.32286.08071.77413.77413,0,0,0,.34977-1.4798.86147.86147,0,0,1-.67263,1.39908Z"
        fill="#111"
      />
      <path
        d="M67.34412,114.05184a.69956.69956,0,1,1-.72654-.69954q.0135-.00027.027,0a.69467.69467,0,0,1,.69954.68977Z"
        fill="#111"
      />
      <path
        d="M67.371,103.8009a.68927.68927,0,0,1-.67263.69954.7123.7123,0,0,1-.69954-.69954.68927.68927,0,0,1,.69954-.67263.6661.6661,0,0,1,.67263.6595Q67.3711,103.79433,67.371,103.8009Z"
        fill="#111"
      />
      <path
        d="M71.54136,105.3076a.6661.6661,0,0,1-.6595.67263q-.00657.00006-.01314,0a.68927.68927,0,0,1-.69954-.67263.7123.7123,0,0,1,.69954-.69954A.68927.68927,0,0,1,71.54136,105.3076Z"
        fill="#111"
      />
      <path
        d="M62.79712,105.3076a.68927.68927,0,0,1-.69954.67263.6661.6661,0,0,1-.67263-.6595q-.00006-.00657,0-.01314a.68927.68927,0,0,1,.67263-.69954A.7123.7123,0,0,1,62.79712,105.3076Z"
        fill="#111"
      />
      <path
        d="M77.08387,122.15035a.54075.54075,0,0,0,.34977.10762.78081.78081,0,0,0,.78026-.78025.74273.74273,0,0,0-.45739-.69954.75123.75123,0,0,1,.21524.53811A.86827.86827,0,0,1,77.08387,122.15035Zm-6.75325-1.18384h0a.63912.63912,0,0,0,.32286.08071.76411.76411,0,0,0,.78026-.74761q.00035-.01632,0-.03264a.82773.82773,0,0,0-.43049-.69954.92978.92978,0,0,1,.18834.53811.85.85,0,0,1-.8388.861q-.01108.00014-.02217,0Zm-14.502,1.18384h-.0269a.54074.54074,0,0,0,.34977.10762.78082.78082,0,0,0,.78025-.78025.76316.76316,0,0,0-.43049-.69954.8666.8666,0,0,1,.18834.53811A.88038.88038,0,0,1,55.82863,122.15035Zm6.75325-1.18384H62.555a.74489.74489,0,0,0,.34977.08071.78081.78081,0,0,0,.78026-.78025.82773.82773,0,0,0-.43049-.69954.92978.92978,0,0,1,.18834.53811.85.85,0,0,1-.8388.861q-.01108.00014-.02217,0Z"
        fill="#111"
      />
      <path
        d="M63.28142,120.10555a.69467.69467,0,0,1-.68977.69954h-.00977a.67861.67861,0,0,1-.69954-.657q-.00067-.02125,0-.04252a.69466.69466,0,0,1,.68976-.69954h.00978A.7123.7123,0,0,1,63.28142,120.10555Z"
        fill="#111"
      />
      <path
        d="M56.52817,121.28939a.71312.71312,0,1,1-.6995-.6995A.71312.71312,0,0,1,56.52817,121.28939Z"
        fill="#111"
      />
      <path
        d="M71.03016,120.10555a.71312.71312,0,1,1-.6994-.6994A.71311.71311,0,0,1,71.03016,120.10555Z"
        fill="#111"
      />
      <path
        d="M77.78341,121.28939a.69467.69467,0,0,1-.68977.69954h-.00977a.7123.7123,0,0,1-.69954-.69954.69466.69466,0,0,1,.68976-.69954h.00978a.67861.67861,0,0,1,.69954.657Q77.78408,121.26814,77.78341,121.28939Z"
        fill="#111"
      />
      <path
        d="M63.84643,101.99824l2.852-4.89678,2.82506,4.89678h2.31386l.24215-.53811H69.84632l-2.852-4.89678,1.39908-2.42148.32286-.32286-.21524-.34977L67.963,93.523l-.05381.43049L66.6984,96.02525l-1.21074-2.07171-.08071-.45739-.53811-.0269-.21524.34977.34977.32286,1.37217,2.39458-2.82506,4.92368H61.29042l.26906.53811Zm18.77995,16.493-.05381,1.15692.83407-.80716Zm-1.56051-.08071,1.15693-.24215-.4843-.72644Zm.5112-2.09862-1.04931.51121,1.10312.32286Zm.43049-1.21075-1.15694-.08071.78026.83406Zm.9955-.78026-.94169-.67263.21524,1.13Zm.78026-1.23764-.37669,1.10312.861-.05381ZM49.96326,118.841l.83407.80716-.05381-1.15692Zm1.66813-1.39908-.45739.72644,1.13.24215Zm.10762-.296,1.10312-.32286-1.0224-.51121Zm.78025-2.12552-1.15693.08071.37667.75335Zm-1.426-.24215.24215-1.13-.96859.67263Zm-1.991-.64573.861.05381-.37667-1.10312Z"
        fill="#111"
      />
    </svg>
  );
};

LAK.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LAK.defaultProps = {
  size: "100"
};

export default LAK;
