import React from "react";
import PropTypes from "prop-types";

const NHL = props => {
  const { size } = props;
  return (
    <svg
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 400 300"
      enable-background="new 0 0 400 300"
    >
      <path
        id="bg"
        fill="#E4E5E6"
        d="M292.555,145.784c-3.009-7.502-5.849-14.588-5.852-26.542c0-8.822,1.862-17.522,5.535-25.861
	l6.343-14.41l0.458-1.04l-1.09-0.322l-14.926-4.405c-6.175-1.82-10.688-6.521-13.413-13.973l-6.579-18.02l-0.486-1.331l-1.091,0.903
	l-14.672,12.148c-5.11,4.233-10.347,6.38-15.565,6.38c-8.296,0-15.655-4.357-19.776-8.435l-9.449-9.346l-0.703-0.696l-0.703,0.696
	l-9.447,9.346c-4.12,4.078-11.479,8.435-19.779,8.435c-5.219,0-10.456-2.146-15.567-6.379L141.12,40.784l-1.091-0.903l-0.486,1.331
	l-6.58,18.021c-2.722,7.45-7.233,12.151-13.413,13.972l-14.925,4.405l-1.09,0.322l0.458,1.04l6.342,14.41
	c3.673,8.338,5.536,17.039,5.536,25.861c0,11.958-2.843,19.048-5.853,26.555c-3.058,7.624-6.218,15.504-6.218,28.768
	c0,7.721,1.279,15.243,3.802,22.357c7.742,21.854,27.364,39.238,51.21,45.373c6.941,1.754,12.132,2.742,16.712,3.616
	c4.808,0.917,8.971,1.712,14.159,3.152c1.875,1.985,10.781,11.611,10.875,11.713l0.733,0.794l0.734-0.794
	c0.093-0.102,8.997-9.724,10.873-11.713c5.188-1.44,9.354-2.235,14.162-3.152c4.58-0.874,9.771-1.864,16.709-3.614
	c14.825-3.816,28.436-12.153,38.322-23.477c10.916-12.498,16.687-27.802,16.687-44.257c0-12.211-2.618-19.761-5.781-27.681
	L292.555,145.784z"
      />
      <path
        id="shield"
        d="M294.019,94.178l7.711-17.504l-18.177-5.363c-5.538-1.636-9.611-5.93-12.112-12.759l-8.027-21.975
	l-17.918,14.836c-4.751,3.933-9.562,5.925-14.307,5.925c-6.343,0-13.563-3.088-18.395-7.865L201.26,38.06l-11.54,11.414
	c-4.827,4.777-12.049,7.865-18.391,7.865c-4.744,0-9.556-1.992-14.307-5.925l-17.915-14.836l-8.031,21.975
	c-2.493,6.83-6.568,11.124-12.113,12.759l-18.173,5.363l7.71,17.504c3.562,8.08,5.365,16.513,5.365,25.063
	c0,23.661-12.066,29.043-12.066,55.323c0,37.861,29.278,62.637,56.484,69.639c13.746,3.346,17.898,3.571,30.31,6.631
	c1.017,1.092,12.667,13.632,12.667,13.632s11.651-12.542,12.665-13.632c12.412-3.06,16.566-3.285,30.312-6.631
	c27.204-7.004,56.486-31.777,56.486-69.639c0-26.279-12.068-31.662-12.068-55.323C288.653,110.688,290.459,102.258,294.019,94.178z
	 M292.042,147.256c2.904,7.271,5.71,14.857,5.71,27.31c0,34.585-25.751,59.426-54.254,66.763
	c-14.093,3.559-20.657,3.905-31.161,6.85c-1.369,1.436-11.071,11.923-11.071,11.923s-9.706-10.49-11.074-11.923
	c-10.504-2.943-17.066-3.291-31.157-6.85c-22.017-5.664-42.38-21.773-50.517-44.738c-2.402-6.777-3.743-14.143-3.743-22.023
	c0-25.828,12.069-30.721,12.069-55.323c0-10.083-2.424-19.008-5.62-26.264l-6.344-14.41l14.926-4.405
	c6.497-1.916,11.231-6.824,14.068-14.588l6.58-18.021l14.673,12.147c4.827,3.998,10.343,6.609,16.206,6.609
	c7.851,0,15.624-3.916,20.481-8.724l9.447-9.346l9.449,9.346c4.859,4.808,12.632,8.724,20.48,8.724
	c5.856,0,11.377-2.611,16.203-6.609l14.674-12.148l6.579,18.021c2.84,7.765,7.567,12.673,14.068,14.588l14.927,4.405l-6.344,14.41
	c-3.195,7.256-5.62,16.181-5.62,26.264C285.682,131.988,288.921,139.438,292.042,147.256z"
      />
      <path
        id="shield_1_"
        d="M288.289,82.965c0,0-23.036-2.008-29.278-30.014c0,0-13.814,14.228-27.817,14.228
	c-18.05,0-29.93-15.345-29.93-15.345s-11.88,15.345-29.931,15.345c-14.004,0-27.817-14.228-27.817-14.228
	c-6.243,28.009-29.283,30.014-29.283,30.014s9.373,16.583,9.373,36.219c0,2.579-0.13,4.957-0.356,7.194
	c-1.985,19.615-11.691,27.483-11.691,48.185c0,25.397,17.398,52.06,49.162,60.096c15.093,3.813,25.51,4.785,33.18,7.554
	c0.981,0.918,7.364,7.836,7.364,7.836s6.381-6.918,7.361-7.836c7.67-2.769,18.089-3.74,33.177-7.554
	c31.767-8.036,49.163-34.696,49.163-60.096c0-23.058-12.045-30.188-12.045-55.378C278.918,99.548,288.289,82.965,288.289,82.965z
	 M284.196,174.564c0,29.357-22.875,48.046-44.084,53.431c-16.796,4.261-27.775,4.75-35.15,8.306c0,0-2.685,2.453-3.698,3.582
	c-1.018-1.129-3.698-3.582-3.698-3.582c-7.376-3.556-18.354-4.045-35.151-8.306c-21.208-5.388-44.086-24.073-44.086-53.431
	c0-20.996,12.053-28.706,12.053-55.378c0-17.972-6.794-31.821-6.794-31.821s15.679-3.163,22.983-23.01c0,0,10.403,9.71,24.764,9.71
	c17.986,0,29.931-12.629,29.931-12.629s11.943,12.629,29.93,12.629c14.359,0,24.761-9.71,24.761-9.71
	c7.306,19.847,22.986,23.01,22.986,23.01s-6.797,13.85-6.797,31.821C272.142,145.858,284.196,153.568,284.196,174.564z"
      />
      <path
        id="shield_2_"
        d="M231.192,81.759c-13.432,0-23.931-5.859-29.931-10.27c-6.004,4.411-16.5,10.27-29.93,10.27
	c-8.867,0-16.415-3-21.735-5.979c-4.952,8.267-11.165,13.072-16.116,15.787c2.128,6.371,4.598,16.216,4.598,27.617
	c0,21.957-7.498,32.241-10.667,44.67l109.967-82.58C235.405,81.583,233.337,81.759,231.192,81.759z"
      />
      <path
        id="shield_3_"
        d="M264.443,119.187c0-11.401,2.473-21.247,4.599-27.617c-4.236-2.327-9.409-6.186-13.909-12.436
	L126.051,176.07c0.617,19.063,12.938,32.943,27.009,40.176l112.313-84.343C264.794,128.114,264.443,123.924,264.443,119.187z
	 M183.443,184.869l-9.988,7.497l-10.772-16.494v24.589l-9.449,7.094v-34.775c0-4.913-4.69-5.044-4.69-5.044l13.393-10.058
	l12.059,18.468v-27.524l9.449-7.096V184.869z M221.55,156.254l-9.238,6.939v-15.848l-10.662,8.002V171.2l-9.708,7.289v-34.778
	c0-4.914-4.69-5.045-4.69-5.045l14.398-10.811v16.018l10.662-8.003v-16.015l9.238-6.947V156.254z M256.415,130.071l-26.801,20.127
	v-34.775c0-4.917-4.69-5.047-4.69-5.047l14.396-10.811v32.28l17.096-12.834C256.415,119.011,255.834,123.135,256.415,130.071z"
      />
      <path
        id="shield_4_"
        d="M162.8,220.125c0.502,0.142,1.006,0.282,1.507,0.404c13.305,3.383,29.578,5.125,34.491,7.934
	c1.109,0.811,2.466,2.25,2.466,2.25s1.354-1.439,2.462-2.25c4.917-2.809,21.189-4.551,34.495-7.934
	c18.432-4.689,38.281-21.061,38.281-45.967c0-12.414-5.445-20.585-9.018-33.056L162.8,220.125z"
      />
    </svg>
  );
};

NHL.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NHL.defaultProps = {
  size: "100"
};

export default NHL;
