// import React from "react";
// import styled from 'styled-components';
// import { FaInstagram, FaTelegram } from "react-icons/fa";
// import { DUBS_BLACK } from "../../Global/StaticElements";

// const Container = styled.div`
//   background-color: ${DUBS_BLACK} ;
//   height: calc(100vh - 70px);
//   font-family: 'Open Sans', sans-serif;
//   width: 100%;
// `;

// const Article = styled.article`
//   height: 20vh;
//   margin-top: 10vh;
//   text-align: center;
//   color: #21F291;
//   h1 {
//     margin-bottom: 2rem;
//     font-size: 2.5rem;
//   }
// `;

// const ContactBox = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-wrap: wrap;
//   a {
//     text-decoration: none;
//     color: inherit;
//   }


// `;

// const ContactItem = styled.div`
//   border: 1px solid black;
//   border-radius: 3rem;
//   margin: 1rem;
//   width: 20rem;
//   height: 300px;
//   padding: 1rem;
//   display: flex;
//   flex-direction: column;
//   text-align: center;
//   justify-content: center;
//   background-color: #21F291;
//   box-shadow: 10px 10px 5px black;
//   h3 {
//     font-size: 2rem;
//   }
//   p {
//     font-size: 1rem;
//     font-weight: 600;
//     color: #000;
//   }
//   svg {
//     font-size: 3rem;
//   }
// `;

// function ContactPage() {
//     return (
//         <Container>
//             <Article id="contact-info">
//                 <h1>You Found our QR page!</h1>
//                 <p>We are a sports betting consulting service with a history of making our clients money</p>
//                 <p>Reach out to us using one of the following contact methods to access our promo!</p>
//             </Article>
//             <ContactBox>
//                 <a id="insta-contact" href="https://instagram.com/dubsadvising" target="_blank" rel="noreferrer">
//                     <ContactItem>
//                         <h3>
//                             Instagram
//                             <FaInstagram />
//                         </h3>
//                         <p>@dubsadvising</p>
//                     </ContactItem>
//                 </a>
//                 <a href="https://t.me/dubs_helper" target="_blank" rel="noreferrer">
//                     <ContactItem>
//                         <h3>
//                             Telegram
//                             <FaTelegram />
//                         </h3>
//                         <p>@dubs_helper</p>
//                     </ContactItem>
//                 </a>
//             </ContactBox>
//         </Container>
//     );
// }

// export default ContactPage;


import React from "react";
import styled from 'styled-components';
import { FaInstagram, FaTelegram } from "react-icons/fa";
import { DUBS_BLACK } from "../../Global/StaticElements";

const Container = styled.div`
  background-color: ${DUBS_BLACK} ;
  height: calc(100vh - 70px);
  font-family: 'Open Sans', sans-serif;
  width: 100%;
`;

const Article = styled.article`
  margin-top: 10vh;
  text-align: center;
  color: #21F291;
  h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
`;

const ContactBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContactItem = styled.div`
  border: 1px solid black;
  border-radius: 3rem;
  margin: 1rem;
  width: 20rem;
  height: 300px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #21F291;
  box-shadow: 10px 10px 5px black;
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
  }
  svg {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 2rem;
  }
`;

function ContactPage() {
    return (
        <Container>
            <Article id="contact-info">
                <h1>You Found our QR page!</h1>
                <p>We are a sports betting consulting service with a history of making our clients money</p>
                <p>Reach out to us using one of the following contact methods to access our promo!</p>
            </Article>
            <ContactBox>
                <a id="insta-contact" href="https://instagram.com/dubsadvising" target="_blank" rel="noreferrer">
                    <ContactItem>
                        <h3>
                            Instagram
                            <FaInstagram />
                        </h3>
                        <p>@dubsadvising</p>
                    </ContactItem>
                </a>
                <a href="https://t.me/dubs_helper" target="_blank" rel="noreferrer">
                    <ContactItem>
                        <h3>
                            Telegram
                            <FaTelegram />
                        </h3>
                        <p>@dubs_helper</p>
                    </ContactItem>
                </a>
            </ContactBox>
        </Container>
    );
}

export default ContactPage;
