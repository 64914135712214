import React from "react";
import PropTypes from "prop-types";

const MIN = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 270.96002 174.82941"
    >
      <path
        d="M252.9798,36.07035c.1093,0,9.4-7.32324,9.4-7.32324a282.68857,282.68857,0,0,0-81.37544-7.43255S174.71948.21925,144.93464.00065c-22.13369-.1093-34.86738,13.66277-38.85691,24.429-41.80807,9.07208-73.7243,30.16739-73.7243,30.16739l7.65115,5.84766C20.27555,70.77378,0,87.2784,0,87.2784S1.85814,97.22489,13.82672,113.183c0,0,8.03371,6.23022,29.23832,14.37323A40.50756,40.50756,0,0,0,49.186,125.5888a71.41125,71.41125,0,0,0,8.90812,2.40465c-.164,2.6779-.98372,6.50348,3.93488,11.03951l-3.607,1.91279s4.37209,11.03951,28.80112,16.99649c9.4-2.186,51.91852-9.94649,66.40106,16.88717l74.48941-72.19407-15.79416-.1093L270.96,41.04359A149.636,149.636,0,0,0,252.9798,36.07035Z"
        fill="#e2d6b5"
      />
      <path
        d="M256.75077,43.99474A174.006,174.006,0,0,0,235.8194,37.9285l7.4872-5.68371c-40.33249-8.41626-68.04058-6.01162-68.04058-6.01162S169.85556,5.13788,142.96723,4.9739c-26.01391-.164-33.993,23.0081-33.993,23.0081C73.28711,34.75874,39.62205,54.97963,39.62205,54.97963l8.143,6.23023C26.66977,71.42961,4.75467,88.8633,4.75467,88.8633c3.00581,9.61859,11.96858,21.36857,11.96858,21.36857,8.307,5.84766,26.61507,13.11626,26.61507,13.11626a25.70253,25.70253,0,0,0,5.51976-2.186A57.27329,57.27329,0,0,0,67.7127,124.933c3.55232.05465,7.4872-.38256,7.54185-1.74883,0-2.40465-7.2686-3.16976-7.2686-5.84766,0-2.02209,4.42674-4.7,21.09532-4.59069,9.94649.05465,21.25926.81977,21.25926.81977-8.19766-.05465-24.37438,1.96744-24.37438,5.73837-.05465,3.00581,10.93021,3.22441,10.93021,7.21394-.05465,5.793-8.63487,7.70581-18.636,7.65114-8.96277-.05465-13.22556-2.62326-15.24765-2.6779-.76511,0-1.257.38256-1.257.92907,0,2.07674,3.93488,6.28487,7.15929,7.4872l-4.37209,2.4593c4.80929,6.44883,22.7895,11.36742,22.7895,11.36742,51.536-10.87557,67.22082,14.91975,67.22082,14.91975l60.71734-62.029-14.15462-.1093Z"
        fill="#024930"
      />
      <path
        d="M117.44519,38.80293a19.21878,19.21878,0,0,1,.21861-3.06046C94.38244,39.13084,72.63131,45.63432,54.92437,55.089A309.99186,309.99186,0,0,1,117.6638,42.19132,23.02171,23.02171,0,0,1,117.44519,38.80293Zm49.40457-5.57443a23.71059,23.71059,0,0,1,.65581,5.957,17.88369,17.88369,0,0,1-.21861,2.95116,161.92514,161.92514,0,0,1,32.13483,6.886c-11.75.32791-23.77322,1.36628-35.4139,2.73255a24.89692,24.89692,0,0,1-21.69648,12.24184,24.61058,24.61058,0,0,1-16.39532-6.23022c-24.21043,4.7-41.20691,9.4-41.20691,9.4,11.96858.65581,18.69067,3.443,18.69067,3.443-30.9325,6.06627-59.78827,17.7616-69.18825,21.80578,2.24069,3.55232,5.08255,7.7058,5.08255,7.7058l-4.64534,5.68371c8.25231,1.58488,18.47206.76511,18.47206.76511l15.4116-9.29068,12.02323,3.71627,3.66162-4.97325H80.28244l8.03371-12.7337,6.55813,12.843H91.86846l2.02209,4.37209,8.307-1.42093,3.55232-4.48139h-3.16977l4.80929-7.2686H103.837l7.54185-12.6244,6.33952,13.11626h-2.84186l4.20813,7.65115-3.38837-.05465,6.886,12.679,6.99533-9.67324H124.823L132.037,87.71562,127.4463,87.661l10.43836-17.59764,8.79883,17.9802h-3.71627l6.06627,10.54766-4.31744-.05465,5.793,13.17091,10.1651-16.34067-5.51976-.05465,8.25231-13.22556-5.0279-.05465L169.473,59.84358l11.31277,23.39066h-4.04418l7.3779,12.29649-5.08255-.05465,5.5744,12.18719,9.34533-14.04532-4.97325-.05465L199.859,78.1517h-5.793l17.379-23.66391,8.96277,16.39532,19.45577-23.60926C217.67524,38.85758,192.3718,34.21223,166.84976,33.2285Z"
        fill="#af1e24"
      />
      <path
        d="M142.63933,13.93668A25.05683,25.05683,0,0,0,117.6638,35.74246s12.6244-5.57441,46.78132-8.74417A25.165,25.165,0,0,0,142.63933,13.93668Zm-.32791,50.06038a24.83133,24.83133,0,0,0,21.69647-12.24184c-13.60812,1.69418-26.61507,3.82558-38.03714,6.01162a24.65938,24.65938,0,0,0,16.34067,6.23023Zm24.53833-30.76855a271.792,271.792,0,0,0-49.186,2.51395,27.80443,27.80443,0,0,0-.21861,3.06046c0,1.14767.1093,2.29535.21861,3.38837a238.80484,238.80484,0,0,1,49.62317-.05465,17.88369,17.88369,0,0,0,.21861-2.95116,26.53547,26.53547,0,0,0-.65581-5.957Z"
        fill="#edaa00"
      />
      <path
        d="M145.31724,48.03894A300.9918,300.9918,0,0,0,83.179,56.83776c-.2186-1.47558-.43721-2.89651-.65581-4.31744-1.03837,1.69418-1.96744,3.33372-3.00581,4.97325,0,0-14.81044,5.3558-24.593,10.3837,12.51509-4.20813,22.6802-6.77673,22.6802-6.77673-1.03837,2.13139-2.02209,4.20813-3.06046,6.39417,1.85814-1.69418,3.66162-3.27906,5.57441-4.9186l3.93488,2.13139c-.164-1.58488-.32791-3.11511-.49186-4.75464C83.56153,59.95287,116.57077,50.9901,145.31724,48.03894Z"
        fill="#fff"
      />
    </svg>
  );
};

MIN.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MIN.defaultProps = {
  size: "100"
};

export default MIN;
