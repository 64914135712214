const BASE_URL: string = 'https://dubs-backend.herokuapp.com/'

type endpointType = "" | "get-odds" | "login" | "sign-up" | "user" | "update-customer-id" | "get-user-paid" | "cancel" | "pay" | "payment-success" | "stripe-payment-success" | "send-user-email" | "retrieve-stripe-user" | "user-cancelled"
type paramsType = string | URLSearchParams | string[][] | Record<string, string> | undefined | null
type dataType = BodyInit | null | undefined | object

type dataObjectType = {
    data?: dataType,
    params?: paramsType
}

const GET = ["", "get-odds", "user", "get-user-paid", "pay", "stripe-payment-success", "retrieve-stripe-user"]

export default async function apiWrapper(endpoint: endpointType, dataObject?: dataObjectType){

    let method  = 'POST'

    if (GET.includes(endpoint)){
        method = 'GET'
    }

    let queryParams: URLSearchParams | string;
    const token = localStorage.getItem("dubsToken")
    if (dataObject?.params) {
        queryParams = '?' + new URLSearchParams(dataObject.params)
    } else {
        queryParams = ''
    }

    const out = await fetch(BASE_URL + endpoint + queryParams,
        {
            "method": method,
            "body": JSON.stringify(dataObject?.data),
            "headers":{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            }
        }
    )
    if (out.ok) {
        return await out.json()
    } else {
        return "failed"
    }

}
