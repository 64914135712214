import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {

  const handleChange = (event) => {
    props.setChoice(event.target.value);
  };

  return (
      <Box>
        <FormControl sx={props.styleSelect}>
          <InputLabel sx={props.styleSelect}>{props.label}</InputLabel>
          <Select
            value={props.value}
            label={props.label}
            onChange={handleChange}
            sx={props.styleSelect}
          >
            {
              Object.keys(props.list).map(
                (item) => (
                  (<MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>)
                )
              )
            }
          </Select>
        </FormControl>
      </Box>
  );
}
