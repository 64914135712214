import { useNavigate } from 'react-router-dom';
import Loading from '../../Global/LoadingSpinner';
import styled from 'styled-components';
import { useEffect } from 'react';
import { DubsHeader, DubsSubHeader, MainTextContainer } from '../../Global/StaticElements';

export default function Logout(){
  const navigate  = useNavigate()


  useEffect(() => {
    const handleNav = () => {
      navigate("/")
    }
    if (localStorage.getItem("dubsToken")){
      localStorage.setItem("dubsToken", "")
    }
    setTimeout(
      handleNav, Math.floor(Math.random() * (1000 - 500) + 500)
    )

  }, [navigate])

  return(
    <MainTextContainer>
      <DubsHeader>You are being logged out</DubsHeader>
      <DubsSubHeader>Please wait...</DubsSubHeader>
      <Loading />
    </MainTextContainer>
  )
}



