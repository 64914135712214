import { useSearchParams } from 'react-router-dom';
import { Checkbox, Label, Modal, TextInput } from 'flowbite-react';
import { ButtonWrap, Text, TextWrap } from '../../../Global/StaticElements';
//import apiWrapper from "../../../helpers/ApiWrapper";
import { useState } from "react";
import Button from '../../../Global/Button';
import CancelModal from './CancelModal';
import { triggerFocus } from 'antd/es/input/Input';
import Loading from '../../../Global/LoadingSpinner';
import { cancelUser } from '../../../helpers';
import { userCancelled } from '../../../helpers';

export default function Cancel(){

    const [params, setParams]  = useSearchParams()
    const [emailVerified, setEmailVerified] = useState(params.get("code") === localStorage.getItem("dubsCancelCode"))
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCloseModal = () => setIsModalOpen(false);
    const handleSubmitReason = async (reason: string) => {
        setLoading(true)
        const result = await cancelUser(params.get("cus_id")); //apiWrapper("cancel", {data:{cus_id: params.get("cus_id")}})
        userCancelled(params.get("email"), reason) //apiWrapper("user-cancelled", {data:{funct:"userCancelled", email: params.get("email"), reason: reason}})
        if (result !== "failed"){
            setLoading(false)
            setCancelSuccess(true)
        } else {
            setLoading(false)
            setEmailVerified(false)
        }
    };



    return(loading ? (
        <Loading />
    ):(
        emailVerified ? (
            cancelSuccess ? (
                <TextWrap>
                    <Text>
                        You have successfully cancelled your account
                    </Text>
                </TextWrap>
            ):(
                <>
                    <TextWrap>
                        <Text>
                            We're sorry to see you go! Please fill out the quick form to cancel your subscription
                            <ButtonWrap>
                                <Button 
                                    onClick={() => setIsModalOpen(true)} 
                                    text={"Cancel Subscription"}
                                />
                            </ButtonWrap>
                        </Text>
                    </TextWrap>
                    <CancelModal
                        show={isModalOpen}
                        onClose={handleCloseModal}
                        onSubmit={handleSubmitReason}
                    />
                </>
            )
            
        ):(
            <TextWrap>
                <Text>
                    Oops! Something went wrong, please contact our telegram for assistance.
                </Text>
            </TextWrap>
        )
        
    )
    )
}