import styled from "styled-components"
import { DUBS_GREEN } from "../../Global/StaticElements"
import { NavLink } from "react-router-dom"


const Section2 = () => {
    return (
        <Section2Wrap>
            <Img className="desktop" src={require('../../images/updatedDubsPlans.png')}/>
            <Img className="mobile" src={require('../../images/dubsmobileplans.png')} />
            <PlansTitle>
                <StyledNavLink to={'/plans'}>View our plans.</StyledNavLink>
            </PlansTitle>
        </Section2Wrap>
    )
}

const StyledNavLink = styled(NavLink) `
    background-color: ${DUBS_GREEN};
    border-radius: 25px;
    padding: 10px;
    border: none;
    margin-top: 10px;
    font-size: 20px;
    text-decoration: none;
    color: #000;
`

const Img = styled.img `
&.mobile {
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}

&.desktop {
    @media (max-width: 767px) {
        display: none;
    }
}


`

const PlansTitle = styled.div`
    display: flex;
    justify-content: center;
`
const H1 = styled.h1`
    color: #21F292;
    font-family: 'Poppins';
`

const Section2Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

export default Section2