import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Hamburger from 'hamburger-react'
import '../../css/navbar.css'
import { useLocation } from 'react-router-dom'
import dubslogo from '../../images/Dubs-Logo-NoText.png'
import styled from 'styled-components'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)
  let token = localStorage.getItem("dubsToken");
  useEffect(() => {
    token = localStorage.getItem("dubsToken")
  }, [token])
  const location = useLocation()

  return (
    <nav className="navbar">
      <div className="container">
        <div className="menu-icon">
          <Hamburger toggled={showNavbar} toggle={() => setShowNavbar(!showNavbar)}/>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/plans">Plans</NavLink>
            </li>
            <li>
              <NavLink to="/arbitrage">Arbitrage</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              { token ? (
                <NavLink to="/logout">Logout</NavLink>
              ) : (
                <NavLink to={`/login?next=${location.pathname}`}>Login</NavLink>
              )}
            </li>
          </ul>
        </div>
        <NavLink to='/'><Img src={dubslogo}/></NavLink>
      </div>
    </nav>
  )
}

const Img = styled.img `
  height: 50px;
  width: 50px;
`

export default Navbar
