import React from "react";
import PropTypes from "prop-types";

const PHI = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 298.48051 208.56001"
    >
      <path d="M202.7405,0H.0005V9.54a55.36523,55.36523,0,0,0,15.66,38.82H.0005v9.6a55.32831,55.32831,0,0,0,15.66,38.76H.0005v9.54a55.36525,55.36525,0,0,0,15.66,38.82H.0005v9.6c0,29.7,22.68,53.88,50.64,53.88h47.04l31.92-53.94A94.65093,94.65093,0,1,0,202.7405,0Z" />
      <path
        d="M202.7405,3.18H3.0605V9.42a51.69,51.69,0,0,0,21.12,42.12H3.0605V57.9a51.85859,51.85859,0,0,0,21.12,42.12H3.0605v6.24a51.69,51.69,0,0,0,21.12,42.12H3.0605v6.36c0,27.9,21.3,50.64,47.52,50.64h45.18l33.36-56.34a91.54634,91.54634,0,1,0,73.62-145.86Z"
        fill="#fff"
      />
      <path d="M202.7405,9.42H11.2205c0,24.54,18.48,44.4,41.16,44.4h72.18a2.06156,2.06156,0,0,1,2.04,2.04,2.10285,2.10285,0,0,1-2.04,2.04H11.2205c0,24.48,18.48,44.34,41.16,44.34h43.5a2.01022,2.01022,0,1,1,.05972,4.02q-.02986.00045-.05972,0h-84.66c0,24.54,18.48,44.34,41.16,44.34h14.88a2.07021,2.07021,0,0,1,0,4.14h-56.04c0,24.48,18.48,44.34,41.16,44.34h39.78l74.34-125.7a42.10474,42.10474,0,1,1-5.82,22.26l-28.14,47.58a85.31718,85.31718,0,1,0,70.2-133.8Z" />
      <path
        d="M226.98049,94.74a24.21,24.21,0,1,1-24.24-24.24A24.21,24.21,0,0,1,226.98049,94.74Z"
        fill="#f74902"
      />
    </svg>
  );
};

PHI.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PHI.defaultProps = {
  size: "100"
};

export default PHI;
