import {
    ATL,
    BKN,
    BOS,
    CHA,
    CHI,
    CLE,
    DAL,
    DEN,
    DET,
    GSW,
    HOU,
    IND,
    LAC,
    LAL,
    MEM,
    MIA,
    MIL,
    MIN,
    NOP,
    NYK,
    OKC,
    ORL,
    PHI,
    PHX,
    POR,
    SAC,
    SAS,
    TOR,
    UTA,
    WAS,
} from "react-nba-logos";

export const NBATeamLogos = {
    "Atlanta Hawks": <ATL size={40} />,
    "Brooklyn Nets": <BKN size={40} />,
    "Boston Celtics": <BOS size={40} />,
    "Charlotte Hornets": <CHA size={40} />,
    "Chicago Bulls": <CHI size={40} />,
    "Cleveland Cavaliers": <CLE size={40} />,
    "Dallas Mavericks": <DAL size={40} />,
    "Denver Nuggets": <DEN size={40} />,
    "Detroit Pistons": <DET size={40} />,
    "Golden State Warriors": <GSW size={40} />,
    "Houston Rockets": <HOU size={40} />,
    "Indiana Pacers": <IND size={40} />,
    "Los Angeles Clippers": <LAC size={40} />,
    "Los Angeles Lakers": <LAL size={40} />,
    "Memphis Grizzlies": <MEM size={40} />,
    "Miami Heat": <MIA size={40} />,
    "Milwaukee Bucks": <MIL size={40} />,
    "Minnesota Timberwolves": <MIN size={40} />,
    "New Orleans Pelicans": <NOP size={40} />,
    "New York Knicks": <NYK size={40} />,
    "Oklahoma City Thunder": <OKC size={40} />,
    "Orlando Magic": <ORL size={40} />,
    "Philadelphia 76ers": <PHI size={40} />,
    "Phoenix Suns": <PHX size={40} />,
    "Portland Trail Blazers": <POR size={40} />,
    "Sacramento Kings": <SAC size={40} />,
    "San Antonio Spurs": <SAS size={40} />,
    "Toronto Raptors": <TOR size={40} />,
    "Utah Jazz": <UTA size={40} />,
    "Washington Wizards": <WAS size={40} />,
};