import { ButtonWrap, Text, TextWrap } from '../../../Global/StaticElements';
//import apiWrapper from "../../../helpers/ApiWrapper";
import { useState } from "react";
import Loading from '../../../Global/LoadingSpinner';
import { RetrieveStripeUser } from '../../../helpers';

export default function RequestCancel(){
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)

    const [text, setText ] = useState("");

    const [ email, setEmail ] = useState("");

    const handleChange = (event: any) => {
		setEmail(event.target.value);
	  };

    const handleSubmit = async (e: any) => {
		e.preventDefault()
        setLoading(true)
        const result = await RetrieveStripeUser(email) //apiWrapper("retrieve-stripe-user", {params:{email: email}})
        if (result !== "failed"){
            if (result.type === "stripe"){
                localStorage.setItem("dubsCancelCode", result.code);
                setText("Please check your email to proceed, if you haven't received anything in 5 minutes please try again or contact us through telegram.")
            } else (
                setText("It seems you subscribed using PayPal, please cancel through your PayPal account. For help please contact us through our telegram.")
            )
            setSuccess(true)
        } else {
            setEmail("")
            setError(true)
        }
        setLoading(false)
    }

    return ( loading ?
        (
            <Loading />
    ):(
            error ? (
                <>
                    <TextWrap>
                        <Text>
                            Something went wrong, please try again
                            <ButtonWrap>
                            <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" value={email} onChange={handleChange}/>
                                <span className="focus-input100"></span>
                                {email ? <span className="label-input100"></span> : <span className="label-input100">Email</span> }
                            </div>
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" onSubmit={(e) => handleSubmit(e)}>
                                                Enter
                                    </button>
                                </div>
                            </form>
                        </ButtonWrap>
                        </Text>
                        
                    </TextWrap>
                </>
            ) : (success ? (
                <>
                    <TextWrap>
                        <Text>
                            {text}
                        </Text>
                    </TextWrap>
                </>
            ):(
                <>
                    <TextWrap>
                        <Text>
                            We're sorry to see you go! Enter your email to cancel your account
                            <ButtonWrap>
                            <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" value={email} onChange={handleChange}/>
                                <span className="focus-input100"></span>
                                {email ? <span className="label-input100"></span> : <span className="label-input100">Email</span> }
                            </div>
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" onSubmit={(e) => handleSubmit(e)}>
                                                Enter
                                    </button>
                                </div>
                            </form>
                        </ButtonWrap>
                        </Text>
                    </TextWrap>
                </>
                )
            )
        ));
        
}
