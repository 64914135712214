import React from "react";
import PropTypes from "prop-types";

const CAR = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 311.02819 187.00579"
    >
      <path
        d="M310.289,54.62809c-4.26531-29.73184-30.23366-38.95247-45.78954-41.83784l13.29777-8.78156S208.0466-11.10813,117.84747,17.36923C35.4263,43.40027-6.16064,84.23451.73918,132.34489c4.26533,29.73183,30.23364,38.95248,45.85224,41.83784l-13.36051,8.78155s69.75065,15.17955,159.94977-13.29779C275.60188,143.57272,317.18882,102.80122,310.289,54.62809Z"
        fill="#a4a9ad"
      />
      <path
        d="M306.14909,55.94532c-5.64528-39.2661-54.132-39.95608-54.132-39.95608L266.00484,6.7686s-66.1753-10.726-147.53013,14.92864C46.65412,44.40384-2.209,81.72546,4.879,131.02762c5.64529,39.2661,54.132,39.95608,54.132,39.95608l-13.98777,9.22064s66.1753,10.726,147.53013-14.92864C264.374,142.56909,313.23709,105.24748,306.14909,55.94532Z"
        fill="#fff"
      />
      <path
        d="M298.62206,58.32888c-7.21341-50.18032-84.86746-39.517-125.01172-26.84647a279.74827,279.74827,0,0,0-35.50258,13.67413c72.76147-26.031,123.81994-7.46432,123.81994-7.46432-24.212-1.69358-46.85588,6.14709-46.85588,6.14709S239.472,48.7319,242.671,71.37576c4.26533,29.85729-21.13846,64.10536-99.60793,88.88189a362.49543,362.49543,0,0,1-78.72038,15.61863s50.74485,7.46432,127.33256-16.685C262.555,136.79838,304.70643,100.66853,298.62206,58.32888Z"
        fill="#ce1126"
      />
      <path
        d="M155.60814,122.62242c21.76571-6.89979,40.26971-17.124,50.18032-26.90919,5.51983-5.39439,8.4052-10.66331,7.778-15.17955a8.111,8.111,0,0,0-2.1954-4.45351c-7.58977-8.217-33.05628-8.15431-62.28632,1.06633-33.74626,10.726-59.71458,29.54367-57.89555,42.026C92.94547,131.78035,121.79916,133.223,155.60814,122.62242Z"
        fill="#ce1126"
      />
      <path
        d="M152.66007,108.94828c14.11321-4.45351,25.02744-11.98055,24.33745-16.87313-.69-4.82986-12.73326-5.08076-26.84648-.69-14.05049,4.51623-24.96471,11.98055-24.27473,16.87313C126.56631,113.08816,138.60957,113.40179,152.66007,108.94828Z"
        fill="#111"
      />
      <path
        d="M49.16317,149.28074c24.14928,1.69358,46.85587-6.08437,46.85587-6.08437s-24.40018-4.95531-27.6619-27.59918C64.09182,85.7399,89.55833,51.49183,167.96507,26.7153a363.18251,363.18251,0,0,1,78.72038-15.61862s-50.74484-7.46432-127.33256,16.685C48.47318,50.23733,6.38444,86.30443,12.40608,128.64408c7.21342,50.18032,84.86747,39.517,125.01172,26.84648a283.51656,283.51656,0,0,0,35.56531-13.67414C100.15889,167.91019,49.16317,149.28074,49.16317,149.28074Z"
        fill="#111"
      />
      <path
        d="M144.50578,58.39161c-26.90919,8.46793-40.95969,19.2567-45.85227,30.54727l-9.78516,22.64387s11.91783-21.26391,59.96548-36.44346c36.19255-11.47875,58.33462-8.53065,62.53723.94088a8.74583,8.74583,0,0,1,2.1954,4.51623c.62726,4.45351-2.25812,9.72244-7.778,15.11682-9.09518,12.73326-26.40739,26.47012-41.58694,35.75348,12.106-3.82625,66.6771-29.66911,62.7254-57.26829C223.22615,48.54372,191.36165,43.58844,144.50578,58.39161Z"
        fill="#111"
      />
    </svg>
  );
};

CAR.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CAR.defaultProps = {
  size: "100"
};

export default CAR;
