import styled from "styled-components";
import { ButtonWrap, Text} from "../../Global/StaticElements";
import Sports from "./Sports";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicSelect from "./DropDowns";
import Toggle from "./toggleSwitch";

const Arbitrage = () => {

    const sportCodeDict = {
        "NBA": "basketball_nba",
        "NHL": "icehockey_nhl",
        "NFL": "americanfootball_nfl",
        "MLB": "baseball_mlb"
    }

    const marketCodeDict = {
        "Head 2 Head" : "h2h",
        "Spreads" : "spreads",
        //"Totals" : "totals"
    }

    const token = localStorage.getItem("dubsToken")
    const [sport, setSport] = useState(Object.keys(sportCodeDict)[0])
    const [market, setMarket] = useState(Object.keys(marketCodeDict)[0])
    const [odds, setOdds] = useState("decimal")
    let navigate = useNavigate(); 
    const routeChange = () => {
        navigate("/login?next=arbitrage");
    }

    //TODO add tabs for setting sport and odds format

    return (
        <PAGE>
            {token ? (
                <>  
                    <Selections>
                        <SelectionItem1>
                            <BasicSelect styleSelect={styleSelect} list={sportCodeDict} setChoice={setSport} label={"Sport"} value={sport} />
                        </SelectionItem1>
                        <SelectionItem2>
                          <BasicSelect styleSelect={styleSelect}list={marketCodeDict} setChoice={setMarket} label={"Market"} value={market}/>
                        </SelectionItem2>
                        <ToggleItem>
                          <Toggle setChoice={setOdds} choices={["Decimal","American"]}/>
                        </ToggleItem>
                    </Selections>
                        <Sports oddsFormat={odds} sportCode={sportCodeDict[sport]} market={marketCodeDict[market]} sport={sport}/>
                </>
                ):(
                <Text>
                    Please Login to use DUBS Arbitrage Services
                    <ButtonWrap>
                        <button className="login100-form-btn" onClick={routeChange}>
                            Login
                        </button>
                    </ButtonWrap>
                </Text>
                )   
          }
            
        </PAGE>
    )
}

export default Arbitrage;

const styleSelect = {
    color: 'white',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#20b766',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white',
      },
      '.MuiSvgIcon-root ': {
        fill: "white !important",
      },
      '.MuiInputLabel-root': {
        color: '#20b766',
      },
        '&.Mui-focused .MuiInputLabel-root':{
        color: '#fff',
      },
      '&:hover .MuiInputLabel-root':{
        color: 'white',
      },
}

const Selections = styled.div`
display: flex;
width: 64%;
min-width: 431px;
margin: auto;
margin-bottom: 20px;
margin-top: 20px;
align-items: center;
justify-content: space-around;
`
const PAGE = styled.div`
position: absolute;
top: 80px;
width: 100%;
`
const SelectionItem2 = styled.div`
    width: 100%;
    align-items: center;
    display: flex;
    justify-content:space-around;
`

const SelectionItem1 = styled.div`
    align-items: center;
    display: flex;
`

const ToggleItem = styled.div`
    width: 33%;
    min-width: 210px;
    align-items: center;
    display: flex;
    justify-content:space-around;
`