import { useEffect, useRef, useState } from 'react';
//import apiWrapper from '../../helpers/ApiWrapper';
import { useNavigate } from 'react-router-dom';
import { DubsHeader, DubsSubHeader, MainTextContainer } from '../../Global/StaticElements';
import LoadingSpinner from '../../Global/LoadingSpinner';
import { PaymentSuccess } from '../../helpers';

export default function Success(){
    const notRun = useRef(true)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
	const urlParams = new URLSearchParams(window.location.search);
    const PayerID = urlParams.get('PayerID');
	const paypalToken = urlParams.get('token');
    const paymentId = urlParams.get('paymentId')

    useEffect(() => {
        const handleOpen = async () => {
            const response = await PaymentSuccess(PayerID, paymentId, paypalToken);
            notRun.current = false;
            if(response === "failed"){
                navigate("/payment-failure");
            }
            setLoading(false);
            // if (PayerID || paymentId || paypalToken){
            //     if (PayerID && paymentId){
            //         const response = await apiWrapper("payment-success", {data:{
            //             "type": "paypal",
            //             "PayerID": PayerID,
            //             "paymentId": paymentId
            //         }})
            //         notRun.current = false
            //         if (response === "failed"){
            //             navigate("/payment-failure")
            //         }
            //         setLoading(false)
            //     } else {
            //         const response = await apiWrapper("payment-success", {data:{
            //             "type": "paypal",
            //             "paypalToken": paypalToken
            //         }})
            //         notRun.current = false
            //         if (response === "failed"){
            //             navigate("/payment-failure")
            //         }
            //         setLoading(false)
            //     }
            // } else {
            //     const response = await apiWrapper("payment-success", {data:{
            //         "type": "stripe",
            //     }})
            //     notRun.current = false
            //     if (response === "failed"){
            //         navigate("/payment-failure")
            //     }
            //     setLoading(false)
            // }
        }
        if (notRun.current){
            handleOpen()
        }
        
    })

    return(
        <MainTextContainer>
        {loading ? (
            <LoadingSpinner />
        ):(
            <>
                <DubsHeader>Success!</DubsHeader>
                <DubsSubHeader>Thank you for your purchase you should receive a confirmation message soon</DubsSubHeader>
            </> 
        )}
        </MainTextContainer>
      )
}