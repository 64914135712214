import styled from "styled-components"
import fnl from '../../images/fnl.webp'
import '../../css/landing.css'
import { useInView } from 'react-intersection-observer';
import { Link } from "react-scroll";

const Landing = () => {

    const { ref: myRef, inView } = useInView({ threshold: 0.5, delay: 1000});


    return (
    <LandingDiv>
        <LandingContent ref={myRef} className={`fade-in ${inView ? 'is-visible' : 'not-visible'}`}>
            <H1>Getting DUBS,</H1>
            <H1>has never been so easy.</H1>
            <LinkTo to="section1" smooth={true} duration={200}><H2 className="btn">Learn More.</H2></LinkTo>
        </LandingContent>
    </LandingDiv>
    )
}

const LinkTo = styled(Link)`
    
`

const LandingContent = styled.div`
display: flex;
flex-direction: column;
border: 1px solid #21F292;
padding: 3rem;
@media (max-width: 767px) {
    padding: 2rem;
}
    
`

const LandingDiv = styled.div`
height: 100vh;
width: 100vw;
display: flex;
justify-content: center;
align-items: center;
background-image: url(${fnl});
background-repeat: none;
background-size: cover;

`

const H1 = styled.h1`
    color: #21F292;
    font-family: 'Poppins';
`
const H2 = styled.h2`
    color: #21F292;
    font-family: 'Poppins';
`

export default Landing;