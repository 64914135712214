import React from "react";
import PropTypes from "prop-types";

const NYR = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 140.76199 135.71001"
    >
      <path
        d="M94.802,0l5.46,5.091L105.24.25h35.522L130.145,19.096v91.959a15.5784,15.5784,0,0,1-15.57682,15.58H85.429a17.351,17.351,0,0,0-15.048,9.075,17.35351,17.35351,0,0,0-15.048-9.075H26.196a15.57926,15.57926,0,0,1-15.579-15.57952V19.096L0,.25H35.523l4.976,4.841L45.96,0Z"
        fill="#0038a8"
      />
      <path
        d="M129.25729,18.8631l9.986-17.725h-33.643l-5.325,5.179-5.824-5.43h-48.141l-5.823,5.43-5.325-5.179H1.5183l9.986,17.725v92.192A14.69137,14.69137,0,0,0,26.195,125.7471H55.3333a18.188,18.188,0,0,1,15.047,8.239,18.191,18.191,0,0,1,15.048-8.239h29.138a14.708,14.708,0,0,0,14.691-14.692Z"
        fill="#fff"
      />
      <path
        d="M125.353,18.8631v-1.024l.502-.893,6.706-11.904H107.186l-4.188,4.074-2.666,2.592-2.72-2.535-4.699-4.381H47.848L43.15,9.1731l-2.72,2.535-2.666-2.592-4.188-4.074H8.201l6.705,11.904.504.893v93.216a10.78552,10.78552,0,0,0,10.78306,10.788H55.332c5.58,0,11.239,1.789,15.049,6.99,3.808-5.052,9.469-6.99,15.048-6.99h29.138a10.79982,10.79982,0,0,0,10.786-10.788Z"
        fill="#0038a8"
      />
      <rect
        x="18.4016"
        y="39.36639"
        width="103.94999"
        height="2.505"
        fill="#fff"
      />
      <polygon
        points="53.05 45.628 49.445 45.628 122.356 97.833 122.356 95.253 53.05 45.628"
        fill="#fff"
      />
      <polygon
        points="122.356 90.133 122.356 45.628 60.2 45.628 122.356 90.133"
        fill="#ce1126"
      />
      <polygon
        points="18.428 57.836 102.565 118.769 106.143 118.769 18.428 55.245 18.428 57.836"
        fill="#fff"
      />
      <path
        d="M87.86861,118.7784c-.039-.004-.101-.006-.146-.01h-.097Z"
        fill="#fff"
      />
      <path
        d="M101.9062,17.44852h-6.857v18.786h3.006v-9.223h1.833l3.239,9.223h3.005l-3.301-9.348a3.61111,3.61111,0,0,0,2.832-3.52v-2.207a3.94749,3.94749,0,0,0-3.757-3.711m-.573,6.707h-3.278v-3.701h3.23s1.372-.028,1.372,1.221v1.259a1.22914,1.22914,0,0,1-1.23359,1.22466q-.04525-.00016-.09041-.00366"
        fill="#fff"
      />
      <path
        d="M106.21838,91.0677l-3.11-2.272-.758-.554-1.668-1.22-11.082,15.17,2.426,1.774,4.954-6.782,1.481,1.083-2.339,8.691,2.427,1.773,2.364-8.83a3.61115,3.61115,0,0,0,4.362-1.171l1.789-2.449a3.94814,3.94814,0,0,0-.846-5.213m-4.906,5.745-2.646-1.935,2.67-3.655,2.609,1.907a1.27712,1.27712,0,0,1,.40839,1.75934l-.02239.03466-1.229,1.685a1.23029,1.23029,0,0,1-1.72047.2593q-.03576-.02637-.06953-.0553"
        fill="#fff"
      />
      <path
        d="M86.549,36.23552h1.126s4.133-.565,4.18-4.134v-10.379a4.15122,4.15122,0,0,0-3.945-4.274H86.549c-3.993,0-4.181,4.04-4.181,4.04v10.285c0,4.133,4.181,4.462,4.181,4.462m.797-3.006h-.375a1.61918,1.61918,0,0,1-1.59705-1.641l.00005-.003v-9.487a1.66285,1.66285,0,0,1,1.597-1.644h.423a1.73446,1.73446,0,0,1,1.455,1.691v9.44a1.67847,1.67847,0,0,1-1.503,1.644"
        fill="#fff"
      />
      <polygon
        points="42.166 20.455 42.166 17.449 37.657 17.449 35.966 17.449 34.652 17.449 34.652 36.235 35.966 36.235 37.657 36.235 42.166 36.235 42.166 33.23 37.657 33.23 37.657 28.251 42.166 28.251 42.166 25.246 37.657 25.246 37.657 20.455 42.166 20.455"
        fill="#fff"
      />
      <polygon
        points="95.306 86.881 97.1 84.47 93.482 81.778 92.126 80.768 91.072 79.984 79.857 95.055 80.911 95.84 82.268 96.849 85.885 99.542 87.68 97.13 84.063 94.439 87.035 90.444 90.651 93.135 92.446 90.724 88.829 88.032 91.689 84.189 95.306 86.881"
        fill="#fff"
      />
      <polygon
        points="27.607 17.449 27.607 29.245 22.534 17.449 19.528 17.449 19.528 36.235 22.534 36.235 22.534 24.44 27.607 36.235 30.613 36.235 30.613 17.449 27.607 17.449"
        fill="#fff"
      />
      <polygon
        points="77.578 17.449 75.043 24.35 72.505 17.449 69.5 17.449 73.538 28.439 73.538 36.235 76.545 36.235 76.545 28.439 80.584 17.449 77.578 17.449"
        fill="#fff"
      />
      <polygon
        points="121.443 36.235 115.769 23.4 119.47 17.449 116.465 17.449 112.519 23.773 112.519 17.449 109.514 17.449 109.514 36.235 112.519 36.235 112.519 28.627 113.998 26.248 118.437 36.235 121.443 36.235"
        fill="#fff"
      />
      <polygon
        points="58.792 17.449 56.895 28.945 54.941 17.449 51.935 17.449 49.98 28.945 48.084 17.449 45.078 17.449 48.178 36.235 48.741 36.235 51.184 36.235 51.747 36.235 53.438 26.29 55.129 36.235 55.692 36.235 58.134 36.235 58.698 36.235 61.798 17.449 58.792 17.449"
        fill="#fff"
      />
      <polygon
        points="73.38 67.361 66.516 76.955 69.255 64.41 66.81 62.661 55.879 77.94 58.324 79.689 65.188 70.094 62.45 82.641 64.894 84.389 75.825 69.111 73.38 67.361"
        fill="#fff"
      />
      <path
        d="M84.36459,75.302l-1.414-1.042-.114-.082c-3.243-2.327-5.751.844-5.751.844l-5.998,8.356c-2.411,3.357.794,6.062.794,6.062l1.337.985a6.52812,6.52812,0,0,0,2.071.612l-.06.759,1.315.939,2.036-2.721a3.97876,3.97876,0,0,0,.444-.508l1.967-2.739,1.368-1.907-2.448-1.743-2.445-1.736-1.367,1.91,2.443,1.733-1.658,2.309a1.67958,1.67958,0,0,1-2.18.459l-.728-.547a1.62089,1.62089,0,0,1-.33864-2.26714l.00064-.00086,5.533-7.706a1.66051,1.66051,0,0,1,2.255-.403l.766.575a1.73371,1.73371,0,0,1,.197,2.222l-1.221,1.7,2.434,1.765,1.476-2.056a4.15192,4.15192,0,0,0-.714-5.772"
        fill="#fff"
      />
      <path
        d="M60.61839,58.11852l-2.352-1.809-15.231,12.239,2.424,1.778,3.248-2.471,4.062,3.011-1.343,3.824,2.423,1.778Zm-4.034,3.149-2.865,7.045-2.959-2.113Z"
        fill="#fff"
      />
      <path
        d="M116.5407,97.3275c2.076,1.738,2.79,3.541,1.652,5.787a19.04252,19.04252,0,0,1-1.474,2.235l-2.367-1.765s2.376-2.246.489-3.899a2.66687,2.66687,0,0,0-2.611-.62c-1.579.902-1.588,2.526-1.063,4.331,1.044,2.798,2.518,6.696.245,9.402-1.278,1.276-3.438,2.112-4.941,1.362a8.42287,8.42287,0,0,1-4.64-4.03c-1.043-2.762,2.085-5.89,2.085-5.89l2.03,1.428s-2.189,2.621-1.326,3.767c.311.883,2.621,2.536,4.125,1.784,3.512-2.658-3.551-8.557-.095-12.989a6.089,6.089,0,0,1,3.232-2.105c1.879-.45,3.231.338,4.659,1.202"
        fill="#fff"
      />
      <path
        d="M70.381,124.5938c-5.304-6.374-14.893-5.873-17.319-5.824H27.837c-10.52,0-9.408-9.828-9.408-9.828V62.9748l77.04,55.795H85.577c-2.473-.001-9.477-.397-15.196,5.824"
        fill="#fff"
      />
      <path
        d="M49.2401,49.96541l-3.11-2.272-.758-.553-1.668-1.22-11.083,15.17,2.427,1.773,4.954-6.781,1.481,1.082-2.339,8.691,2.427,1.773,2.363-8.829a3.61085,3.61085,0,0,0,4.362-1.171l1.79-2.45a3.94833,3.94833,0,0,0-.846-5.213m-4.906,5.745-2.647-1.934,2.671-3.655,2.608,1.906a1.27709,1.27709,0,0,1,.40955,1.75905l-.02255.03495-1.229,1.685a1.23016,1.23016,0,0,1-1.72025.25948q-.03587-.02646-.06975-.05548"
        fill="#fff"
      />
      <path
        d="M81.67619,79.7913c-.29.385-1.079.279-1.769-.237-.688-.517-1.011-1.246-.721-1.63l.825-1.105c.289-.383,1.078-.278,1.767.239.689.513,1.014,1.244.724,1.63Z"
        fill="#0038a8"
      />
      <path
        d="M76.023,87.7525c-.29.385-1.078.28-1.767-.235-.686-.517-1.009-1.245-.719-1.63l.826-1.104c.288-.383,1.077-.278,1.764.238.688.512,1.012,1.242.722,1.628Z"
        fill="#0038a8"
      />
      <path
        d="M74.63029,90.6854c-.235.311-1.225-.04-2.22-.784-.992-.746-1.608-1.6-1.372-1.909l.664-.891c.234-.31,1.226.04,2.219.787.992.741,1.612,1.596,1.375,1.907Z"
        fill="#fff"
      />
      <path
        d="M84.58141,77.6567c-.235.311-1.349-.132-2.497-.991-1.147-.863-1.886-1.809-1.652-2.12l.665-.891c.233-.308,1.351.134,2.497.996,1.146.857,1.89,1.806,1.653,2.116Z"
        fill="#fff"
      />
    </svg>
  );
};

NYR.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

NYR.defaultProps = {
  size: "100"
};

export default NYR;
