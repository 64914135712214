import { ButtonWrap, Text, TextWrap } from '../../Global/StaticElements';
//import apiWrapper from "../../helpers/ApiWrapper";
import Loading from '../../Global/LoadingSpinner';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '../../Global/Button';
import { StripePaymentSuccess } from '../../helpers';

export default function PromoSuccess() {
    
    const [loading, setLoading] = useState(true)
    const urlParams = new URLSearchParams(window.location.search);
	const cs_id = urlParams.get('cs_id') || 'paramsError';
    const navigate = useNavigate()

    useEffect(() => {
        const paidObj = {
            params: {
                "cs_id": cs_id
            }
        }
        console.log(cs_id)
        const handleNav = async () => {
            const result = await StripePaymentSuccess(paidObj.params.cs_id)//apiWrapper("stripe-payment-success", paidObj)
            console.log(result)
            if (result !== "failed" && result.user_paid){
                setLoading(false)
            }
            else {
                navigate("/payment-failure")
            }
        }
        
        handleNav()

      }, [navigate, cs_id])


    const handleSubmit = () => {
        window.location.replace("https://t.me/+6aMEMM3VBxllZmM5")
    }
    // for testing: cs_id=cs_live_b1effFTgXvaqTKGrKqF1C4aUywsIIvVDfZfW8F1k83mriAUQl6daI5ahHJ
    return (
            loading ? (
                <Loading />
            ) : (
                <>
                    <TextWrap>
                        <Text>
                            Thank you for your purchase! Join the telegram group using the button below
                            <ButtonWrap>
                                <Button
                                onClick={handleSubmit}
                                text={"Join!"}
                                />
                        </ButtonWrap>
                        </Text>
                        
                    </TextWrap>
                </>
            )
        );
        
}