import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import Landing from "./Landing";
import PlanCardsV2 from "../PricingCards/PlanCardsV2";
import Footer from "../Global Components/Footer";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from './Section4'
import dubslogo from '../../images/Dubs-Logo-NoText.png'
import { DUBS_BLACK } from "../../Global/StaticElements";


const StickyScroll = () => {

const sections = [
{
    id: 1,
    content: (
    <SectionDiv1 className="section1">
        <Section1/>
    </SectionDiv1>
    ),
},
{
    id: 2,
    content: (
    <SectionDiv4 className="section4">
        <Section4/>
    </SectionDiv4>
    )
},
{
    id: 3,
    background: "#f1c40f",
    content: (
    <SectionDiv2 className="section2">
        <Section2/>
    </SectionDiv2>
    ),
},

{
    id: 4,
    background: "#e67e22",
    content: (
    <SectionDiv3 className="section3">
        <Footer/>
    </SectionDiv3>
    ),
},


    ];

    return (
    <>
    <Landing/>
    <Container className="container">
        {sections.map((section, index) => (
        <SectionWrapper key={index}>
            <Controller>
                <Scene triggerHook="onLeave" duration="100%" pin>
                    <Tween>
                        <StickyDiv>
                            {section.content}
                        </StickyDiv>
                    </Tween>
                </Scene>
            </Controller>
        </SectionWrapper>
        ))}
    </Container>
    </>
    );
}

const SectionDiv4 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

`

const H1 = styled.h1`
    color: #21F292;
    font-family: 'Poppins';
`

const SectionDiv1 = styled.div`
padding-top: 50px;
@media (max-width: 767px) {
    padding-top: 10px;
}
display: flex;
justify-content: center;
/* align-items: center; */
height: 100%;
width: 100%;
border-bottom: 1px solid #fff;


`

const SectionDiv2 = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #232323;
height: 100%;
width: 100%;
border-top: 1px solid #fff;


`

const SectionDiv3 = styled.div`
display: flex;
height: 100%;
width: 100%;
border-top: 1px solid #fff;

`

const SectionWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

const StickyDiv = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #fff;
  padding-bottom: 50px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default StickyScroll