import { useEffect, useState } from "react";
//import apiWrapper from "../../helpers/ApiWrapper";
import './css/main.css'
import Ellipsis from "../../Global/Elipsis";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../Global/ErrorMessage";
import { message } from 'antd';
import { signUp } from "../../helpers";
import { payFunc } from "../../helpers";
import { updateCustomerID } from "../../helpers";


const SignUp = () => {

    const [ formData, setFormData ] = useState({
        phone: '',
        password: ''
    });

	const [ loading, setLoading ] = useState(false)
  const [ checked, setChecked ] = useState(false)

  const handleCheck = () => {
    setChecked(!checked)
  }

  const errorMessage = "Please complete all fields and ensure phone number is not in use";
	const navigate = useNavigate()
	const urlParams = new URLSearchParams(window.location.search);
	const plan = urlParams.get('plan');
	const type = urlParams.get('type');
	let next = urlParams.get('next');
  if (!plan || !type){
    next = '/'
  }



	const handleChange = (event: any) => {
		setFormData({
		  ...formData,
		  [event.target.name]: event.target.value
		});
	  };

    const [ err, setErr ] = useState(false)
    const loginObj = { data: formData };

    const handleSubmit = async (e: any) => {
		e.preventDefault()
		const result = await signUp(loginObj.data.phone, loginObj.data.password) //apiWrapper("sign-up", loginObj)

      if (result !== "failed" && checked) {
        localStorage.setItem( "dubsToken", result.data.token)
        const payObj = {
          auth: result.data.token,
          params: {
            plan: plan,
            type: type
          }
        }
        if (next){
          navigate(next);
        } else {
          //@ts-ignore
          const nextUrl = await payFunc(payObj) //apiWrapper('pay', payObj)
          if (nextUrl !== "failed"){
            if (nextUrl.customer){
              updateCustomerID(nextUrl.customer) //apiWrapper('update-customer-id', {data:{customer_id: nextUrl.customer}})
            }
            setLoading(!loading)
            window.location.replace(nextUrl.url)
          } else {
            setLoading(!loading)
            setErr(true)
          }
        }
      } else {
          setErr(true)
      }

    }


    return (
        <>
        <div style={{backgroundColor: "#666666"}}>

	<div className="limiter">
		<div className="container-login100">

			<div className="wrap-login100">
				<form className="login100-form validate-form" onSubmit={(e) => handleSubmit(e)}>
				{err ? (<ErrorMessage message={errorMessage} />): null}

					<span className="login100-form-title p-b-43">
						Sign Up {!next ? "to continue" : null}
					</span>


					<div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input className="input100" type="text" name="phone" value={formData.phone} onChange={handleChange}/>
						<span className="focus-input100"></span>
						<span className="label-input100">Phone</span>
					</div>


					<div className="wrap-input100 validate-input" data-validate="Password is required">
						<input className="input100" type="password" name="password" value={formData.password} onChange={handleChange}/>
						<span className="focus-input100"></span>
						<span className="label-input100">Password</span>
					</div>

					<div className="flex-sb-m w-full p-t-3 p-b-32">
          <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheck}
            />
            <p>
              I am legally able to sports bet in my region
            </p>

          </label>
					</div>
          <div className="flex-sb-m w-full p-t-3 p-b-32">
						<div>
							<a href={`/login?${plan ? `&plan=${plan}`: ""}${type ? `&type=${type}`: ""}${next ? `&next=${next}`: ""}`} className="txt1">
								Already have an account? Login
							</a>
						</div>
					</div>
					<div className="container-login100-form-btn">

						{loading ? <Ellipsis/> :
              <button className="login100-form-btn">
                Sign Up
              </button>
            }
					</div>

				</form>

				<div className="login100-more">
				</div>
			</div>
		</div>
	</div>




	<script src="vendor/jquery/jquery-3.2.1.min.js"></script>
	<script src="vendor/animsition/js/animsition.min.js"></script>
	<script src="vendor/bootstrap/js/popper.js"></script>
	<script src="vendor/bootstrap/js/bootstrap.min.js"></script>
	<script src="vendor/select2/select2.min.js"></script>
	<script src="vendor/daterangepicker/moment.min.js"></script>
	<script src="vendor/daterangepicker/daterangepicker.js"></script>
	<script src="vendor/countdowntime/countdowntime.js"></script>
	<script src="js/main.js"></script>

</div>
        </>
    )
}

export default SignUp;
