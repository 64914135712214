import React from "react";
import PropTypes from "prop-types";

const CGY = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 282.96002 241.06962"
    >
      <path
        d="M64.89019,153.0655c-13.24056,0-18.26285-10.21579-18.26285-10.21579,4.3945-.97022,7.01978-8.16121,11.24307-8.16121,4.56571,0,4.90814,6.50614,23.45635,12.66984C79.6146,148.10029,73.67918,153.0655,64.89019,153.0655Zm214.81675-52.22033S282.96,91.54253,282.96,76.93225C282.96,32.98727,248.03232,0,199.52162,0c-37.09641,0-63.69169,25.05435-79.158,25.05435-6.392,0-13.92542-12.84107-13.92542-12.84107S95.88,17.40678,97.87745,29.90542c0,0-8.3895-4.96521-21.91542-4.96521-16.55071,0-29.7342,10.55821-33.61506,19.80378C22.9427,52.22033,26.30991,61.0664,9.70213,62.60733c0,0,.68486,17.52092,19.23306,21.40178C19.40428,93.54,0,95.36631,0,95.36631c0,5.93542,13.46883,21.3447,40.23534,16.89313-6.84857,9.87335-1.1985,19.40428-19.63259,28.25035,0,0,2.33993,3.70964,11.87085,6.16371a83.59919,83.59919,0,0,0,2.68236,16.779s-10.10164-2.51114-17.63506,1.25558a31.10676,31.10676,0,0,1,10.72945,21.34471c1.08436,15.63757,9.588,39.66462,35.78377,37.03934-3.995,3.02478-11.87085,5.9925-11.87085,5.9925s7.191,11.985,25.16849,11.985c18.43406,0,23.39927-19.68963,41.4909-19.68963,17.00728,0,25.11141,12.72693,52.84812,12.72693,58.15576,0,99.36132-57.64212,103.98409-95.9941h-71.6246s-7.59049,26.19577-25.51092,26.19577c-11.64257,0-23.57049-8.04707-23.57049-29.96249,0-23.57049,19.975-65.4609,48.85312-65.4609,8.56071,0,11.41428,6.67735,11.41428,14.49613,0,8.44656-3.31014,17.46385-3.31014,17.46385Z"
        fill="#111"
      />
      <path
        d="M50.73648,143.64872c3.13893-.62779,4.33743-5.65007,7.53343-5.65007,2.68236,0,3.53843,6.33493,16.43656,9.35971a23.30425,23.30425,0,0,1-10.04456,2.7965C54.90266,150.15486,50.73648,143.64872,50.73648,143.64872ZM277.367,97.82038a90.5895,90.5895,0,0,0,2.397-20.88813c0-42.06162-32.759-74.19282-80.18532-74.19282-34.24284,0-63.29218,25.39677-78.87268,25.39677-6.67735,0-14.95271-11.41428-14.95271-11.41428s-9.07435,6.10664-2.51114,19.975A46.00791,46.00791,0,0,0,76.19031,27.965c-23.91292,0-31.50341,19.11892-31.50341,19.11892C26.48112,53.4759,27.85084,63.63461,13.12641,64.776c0,0,1.65507,16.09413,21.51594,16.72192C27.05186,95.53753,3.93792,97.5921,3.93792,97.5921s6.56321,12.27035,26.82356,12.27035a50.09213,50.09213,0,0,0,22.20078-5.02228c-20.31745,10.90064-7.24807,26.08163-27.28013,35.955a33.06357,33.06357,0,0,0,9.75921,3.36721A97.64912,97.64912,0,0,0,39.8929,168.8172s-5.593-4.62278-16.66485-2.68236c0,0,7.47635,8.44657,8.04707,19.176.91314,17.23556,10.15871,34.98477,27.90792,34.98477,9.81628,0,15.23807-4.73692,15.23807-4.73692-2.45407,8.96021-17.74918,14.83857-17.74918,14.83857a32.10755,32.10755,0,0,0,20.20327,7.59049c18.83356,0,21.51592-19.91792,41.60505-19.91792,15.40928,0,28.70692,13.29763,53.19055,13.29763,61.92248,0,97.478-65.11846,100.3886-90.11573H206.199s-8.50364,26.08163-27.67964,26.08163c-17.23556,0-26.59527-12.9552-26.59527-32.98728,0-25.45385,20.48864-68.82811,52.04912-68.82811,11.52842,0,14.72442,9.41678,14.72442,17.12142a48.55772,48.55772,0,0,1-2.56821,15.181Z"
        fill="#f3bc52"
      />
      <path
        d="M271.71695,91.20008a57.27734,57.27734,0,0,0,1.54092-14.26785c0-37.3247-31.56048-67.80082-73.67917-67.80082-32.64485,0-58.384,25.45387-79.27218,25.45387-7.81878,0-12.84107-7.191-12.84107-7.191s.57071,16.20828,21.68713,24.88313c-25.62506-1.1985-32.30241-18.31992-52.10619-18.31992-19.57549,0-26.99477,15.46635-26.99477,15.46635a19.9016,19.9016,0,0,1,12.784-4.96521c19.00478,0,17.80628,19.06185,41.2626,35.66963-13.46885,0-18.66235-7.24807-35.09891-7.24807-32.759,0-25.62506,21.63006-51.25012,27.67963a22.67237,22.67237,0,0,0,12.49864,3.5955c18.03456,0,18.1487-8.789,31.04684-8.789,13.18349,0,11.24307,9.47385,26.9377,20.5457-3.65257,1.14143-14.43907-7.01978-25.28263-7.01978-10.72942,0-16.55071,7.47635-18.26285,17.46385s-7.01978,12.55571-7.01978,12.55571c10.7865.62779,11.58549-10.90064,19.34721-10.90064,7.36221,0,12.04207,10.10163,21.51592,11.985,7.5905,1.54092,16.66485,3.65257,20.08914,9.1885-16.15121,1.54092-19.29013,9.70214-33.67213,9.70214a26.88834,26.88834,0,0,1-23.171-12.72693c-.85607,19.00477,11.64256,44.45863,33.67213,44.45863,11.52843,0,19.80378-4.05206,28.19327.45657-26.99477,7.64756-16.15121,30.64734-34.58527,39.26512a19.27238,19.27238,0,0,0,7.81878,1.54092c15.58049,0,18.43406-20.88814,41.60505-20.88814,17.17849,0,30.19078,13.41179,53.13348,13.41179,50.45111,0,87.20511-51.64962,91.48547-76.47567H210.9359s-9.64507,26.42406-32.41656,26.42406c-22.1437,0-33.67212-16.83606-33.67212-40.06412,0-33.10142,25.91042-75.79082,59.06891-75.79082,14.49614,0,22.1437,12.44157,22.1437,23.45634a25.98985,25.98985,0,0,1-1.48385,9.24557Z"
        fill="#ce1126"
      />
      <path
        d="M90.85767,193.30081c-10.72942-3.93793-17.52092,3.88086-27.166,3.88086-17.12142,0-17.69213-20.54571-29.50592-24.31241,0,0,2.7965,5.93543,2.7965,9.588,0,6.56321,3.93793,31.78877,22.31492,31.78877C74.76354,214.246,74.307,201.97566,90.85767,193.30081ZM78.75853,64.37652S69.62711,52.0491,55.188,52.0491c-18.20578,0-23.7417,15.00978-34.64234,16.15121,0,0,3.65257,6.73443,15.06685,6.73443,23.79878,0,20.94521-17.29262,43.146-10.55823Z"
        fill="#ce1126"
      />
      <path
        d="M74.70647,147.35836a23.30425,23.30425,0,0,1-10.04456,2.7965c-9.75921,0-13.92542-6.50614-13.92542-6.50614,3.13893-.62779,4.33743-5.65007,7.53342-5.65007C60.95226,137.99865,61.80833,144.33358,74.70647,147.35836Zm-28.07913-4.50865s5.02228,10.21579,18.26285,10.21579c8.789,0,14.72442-4.96521,16.43656-5.76421-18.54821-6.10664-18.89063-12.61277-23.45635-12.61277C53.64712,134.68851,51.02183,141.87951,46.62734,142.84971Z"
        fill="#111"
      />
    </svg>
  );
};

CGY.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CGY.defaultProps = {
  size: "100"
};

export default CGY;
