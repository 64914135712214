import styled from "styled-components"

const Section1 = () => {
    return (
    <Section1Wrap>
        <DataWrap className="d-flex flex-column">
            <H1>Data Backed Dubs.</H1>
            <p>
                Dubs Advising is a premium handicapping service providing sports betting advising for a fair price. 
                Our clients receive data driven picks, all of which are verified by third party services. No curtains, no lies. 
                All of our picks are public and verified by Sports Data Now. 
            </p>
            <A href="https://app.sportsdatanow.com/capper-profile?cid=Dubs%20Sports%20Advising" target="blank">
                visit Sports Data Now
            </A>
        </DataWrap>
        <ImgWrap>
        <Iframe src="https://app.sportsdatanow.com/capper-profile?cid=Dubs%20Sports%20Advising"
       loading="lazy"
       width="600"
       height="600">
</Iframe>
            {/* <Img src={require('../images/sdn.png')}/> */}
        </ImgWrap>
    </Section1Wrap>
    )
}

const Iframe = styled.iframe`
    @media (max-width: 767px) {
        height: 400px;

  }
`

const A = styled.a `
    text-decoration: none;
    color: #21F292;
`

const Section1Wrap = styled.div`
    display: flex;

    @media (max-width: 767px) {
    flex-direction: column;
}
`

const DataWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50%;
    padding: 20px;
    @media (max-width: 767px) {
    width: 100%;
  }

`



const ImgWrap = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    height: 60vh;
    @media (max-width: 767px) {
    width: 350px;
    height: 400px;

  }
`

const H1 = styled.h1`
    color: #21F292;
    font-family: 'Poppins';
`


export default Section1