import React from 'react';
import {
ARI,
ATL,
BAL,
BUF,
CAR,
CHI,
CIN,
CLE,
DAL,
DEN,
DET,
GB,
HOU,
IND,
JAX,
KC,
LAC,
LAR,
LV,
MIA,
MIN,
NE,
NO,
NYG,
NYJ,
PHI,
PIT,
SEA,
SF,
TB,
TEN,
// WSH,
} from 'react-nfl-logos';

export const NFLTeamLogos = {
'Arizona Cardinals': <ARI size={40} />,
'Atlanta Falcons': <ATL size={40} />,
'Baltimore Ravens': <BAL size={40} />,
'Buffalo Bills': <BUF size={40} />,
'Carolina Panthers': <CAR size={40} />,
'Chicago Bears': <CHI size={40} />,
'Cincinnati Bengals': <CIN size={40} />,
'Cleveland Browns': <CLE size={40} />,
'Dallas Cowboys': <DAL size={40} />,
'Denver Broncos': <DEN size={40} />,
'Detroit Lions': <DET size={40} />,
'Green Bay Packers': <GB size={40} />,
'Houston Texans': <HOU size={40} />,
'Indianapolis Colts': <IND size={40} />,
'Jacksonville Jaguars': <JAX size={40} />,
'Kansas City Chiefs': <KC size={40} />,
'Los Angeles Chargers': <LAC size={40} />,
'Los Angeles Rams': <LAR size={40} />,
'Las Vegas Raiders': <LV size={40} />,
'Miami Dolphins': <MIA size={40} />,
'Minnesota Vikings': <MIN size={40} />,
'New England Patriots': <NE size={40} />,
'New Orleans Saints': <NO size={40} />,
'New York Giants': <NYG size={40} />,
'New York Jets': <NYJ size={40} />,
'Philadelphia Eagles': <PHI size={40} />,
'Pittsburgh Steelers': <PIT size={40} />,
'Seattle Seahawks': <SEA size={40} />,
'San Francisco 49ers': <SF size={40} />,
'Tampa Bay Buccaneers': <TB size={40} />,
'Tennessee Titans': <TEN size={40} />,
// 'Washington Commanders': <WSH size={40} />,
};