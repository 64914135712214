import styled from "styled-components";
import { HiOutlineCubeTransparent, HiCode, HiUserGroup } from 'react-icons/hi'
import { IconContext } from 'react-icons'
import { DUBS_BLACK, DUBS_GREEN } from "../../Global/StaticElements";
import { NavLink } from "react-router-dom";


const Section4 = () => {
    return (

        <PageWrap>
            <PillarWrap>
            <IconContext.Provider value={{color: '#20b766', size: '120px'}}>
            <Pillar>
                <IconWrap><HiOutlineCubeTransparent/></IconWrap>
                <Text>Transparency</Text>
            </Pillar>
            <Pillar>
                <IconWrap><HiUserGroup/></IconWrap>
                <Text>Professionalism</Text>
            </Pillar>
            <Pillar>
                <IconWrap><HiCode/></IconWrap>
                <Text>Innovation</Text>
            </Pillar>
            </IconContext.Provider>
            </PillarWrap>
            <NavAboutWrap>
                <TextTo to={'/about'}>Click here to learn more about dubs.</TextTo>
            </NavAboutWrap>
        </PageWrap>
    )
}

export default Section4;

const TextTo = styled(NavLink) `
text-decoration: none;
color: ${DUBS_GREEN};
font-size: 2rem;
@media (max-width: 767px) {
    font-size: 1rem;
    

}
`

const NavAboutWrap = styled.div`
    
`

const PillarWrap = styled.div `
display:flex;
justify-content: center;
align-items: center;
height: 50%;
width: 100%;
background-color: ${DUBS_BLACK};
@media (max-width: 767px) {
    flex-direction: column;


}

`
const PageWrap = styled.div `
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100%;
width: 100%;
background-color: ${DUBS_BLACK};
@media (max-width: 767px) {
    height: 100vh;
    justify-content: space-evenly;
    

}

`

const Pillar = styled.div `
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin: 0px 30px;
@media (max-width: 767px) {
    margin: 0px 15px;
}

`

const IconWrap = styled.div `

`

const Text = styled.p `
font-size: 2.5rem;
border-bottom: 1px solid white;
@media (max-width: 767px) {
    font-size: 1.5rem;
    

}
`
