import React from 'react';
import {
ARI,
ATL,
BAL,
BOS,
CHC,
CHW,
CIN,
CLE,
COL,
DET,
HOU,
KAN,
LAA,
LAD,
MIA,
MIL,
MIN,
NYM,
NYY,
OAK,
PHI,
PIT,
SD,
SEA,
SF,
STL,
TB,
TEX,
TOR,
WAS,
MLB,
} from 'react-mlb-logos';

export const MLBTeamLogos = {
'Arizona Diamondbacks': <ARI size={40} />,
'Atlanta Braves': <ATL size={40} />,
'Baltimore Orioles': <BAL size={40} />,
'Boston Red Sox': <BOS size={40} />,
'Chicago Cubs': <CHC size={40} />,
'Chicago White Sox': <CHW size={40} />,
'Cincinnati Reds': <CIN size={40} />,
'Cleveland Guardians': <CLE size={40} />,
'Colorado Rockies': <COL size={40} />,
'Detroit Tigers': <DET size={40} />,
'Houston Astros': <HOU size={40} />,
'Kansas City Royals': <KAN size={40} />,
'Los Angeles Angels': <LAA size={40} />,
'Los Angeles Dodgers': <LAD size={40} />,
'Miami Marlins': <MIA size={40} />,
'Milwaukee Brewers': <MIL size={40} />,
'Minnesota Twins': <MIN size={40} />,
'New York Mets': <NYM size={40} />,
'New York Yankees': <NYY size={40} />,
'Oakland Athletics': <OAK size={40} />,
'Philadelphia Phillies': <PHI size={40} />,
'Pittsburgh Pirates': <PIT size={40} />,
'San Diego Padres': <SD size={40} />,
'Seattle Mariners': <SEA size={40} />,
'San Francisco Giants': <SF size={40} />,
'St. Louis Cardinals': <STL size={40} />,
'Tampa Bay Rays': <TB size={40} />,
'Texas Rangers': <TEX size={40} />,
'Toronto Blue Jays': <TOR size={40} />,
'Washington Nationals': <WAS size={40} />,
'MLB': <MLB size={40} />,
};