import apiWrapper from "./ApiWrapper"

export const getOdds = (sport, market) => {

  return apiWrapper("get-odds", {params: {"sport": sport, "market": market}})
  
}

export const loginUser = (phone, password) => {

  return apiWrapper("login", {data: {"phone": phone, "password": password}})
}

export const signUp = (phone, password) => {

  return apiWrapper("sign-up", {data: {"phone": phone, "password": password}})
}

export const StripePaymentSuccess = (cs_id) => {

  return apiWrapper("stripe-payment-success", {params: {"cs_id": cs_id}})
}

export const PaymentSuccess = (PayerID, paymentId, paypalToken) => {
  if (PayerID || paymentId || paypalToken){
    if (PayerID && paymentId){
      return apiWrapper("payment-success", {data:{
          "type": "paypal",
          "PayerID": PayerID,
          "paymentId": paymentId
      }})
    } else {
      return apiWrapper("payment-success", {data:{
          "type": "paypal",
          "paypalToken": paypalToken
      }})
    }
  } else {
    return apiWrapper("payment-success", {data:{"type": "stripe",}})
  }
}

export const payFunc = (payObj) => {
  return apiWrapper('pay', payObj);
}

export const updateCustomerID = (ID) => {
  apiWrapper('update-customer-id', {data:{customer_id: ID}})
}

export const cancelUser = (ID) => { 
  return apiWrapper("cancel", {data:{cus_id: ID}})
}

export const userCancelled = (Email, Reason) => {
  apiWrapper("user-cancelled", {data:{funct:"userCancelled", email: Email, reason: Reason}})
}

export const RetrieveStripeUser = (email) => {
  return apiWrapper("retrieve-stripe-user", {params:{email: email}});
}

export const sendUserEmail = (funct, email) => {
  return apiWrapper("send-user-email", {data:{funct: funct, email: email}})
}