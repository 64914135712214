import { GlobalStyles } from "./Global/GlobalStyles";
import Navbar from './components/Global Components/Header';
import Home from "./components/Home/Home";
import Login from "./components/UserFunctions/Login";
import PlanCardsV2 from "./components/PricingCards/PlanCardsV2";
import Sports from "./components/Arbitrage/Sports";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom"
import Logout from './components/UserFunctions/Logout';
import About, { AboutPhone } from "./components/About/About";
import { ParallaxProvider } from "react-scroll-parallax";
import Contact from "./components/Contact/Contact";
import ScrollToTop from "./ScrollToTop";
import Arbitrage from "./components/Arbitrage/Arbitrage";
import SignUp from './components/UserFunctions/SignUp';
import Success from "./components/PaymentFlow/Success";
import Failure from "./components/PaymentFlow/Failure";
import PromoSuccess  from "./components/PaymentFlow/PromoSuccess"
import UnsubscribeEmail from "./components/UserFunctions/UnsubscribeEmail";
import RequestCancel from "./components/UserFunctions/CancelFlow/RequestCancel";
import Cancel from "./components/UserFunctions/CancelFlow/Cancel";

  const renderAbout = () => {
    let windowSize = window.innerWidth;
    if (windowSize < 767) {
      return <AboutPhone/>
    } return <About/>

  }



const App = () => {
  return (
    <>
    <ParallaxProvider>
      <Router>
      <GlobalStyles/>
      <Navbar />
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={renderAbout()} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/login" element={<Login/>}/>
          <Route path="/logout" element={<Logout />}/>
          <Route path="/plans" element={<PlanCardsV2/>}/>
          <Route path="/arbitrage" element={<Arbitrage/>} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/success" element={<Success/>} />
          <Route path="/success-promo" element={<PromoSuccess/>} />
          <Route path="/payment-failure" element={<Failure />} />
          <Route path="/unsubscribe" element={<UnsubscribeEmail />} />
          <Route path="/request-cancel" element={<RequestCancel />}/>
          <Route path="/cancellation" element={<Cancel />}/>
        </Routes>
      </Router>
    </ParallaxProvider>
    </>

  )
}

export default App;
