import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 49,
  height: 28,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 26,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(21px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#20b766',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 3px 7px 0 rgb(0 35 11 / 20%)',
    width: 21,
    height: 21,
    borderRadius: 11,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 28 / 2,
    opacity: 1,
    backgroundColor:'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function Toggle(props) {

  const handleChange = (event) => {
    event.target.checked ? (props.setChoice(props.choices[1])):(props.setChoice(props.choices[0]))
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography fontSize="1.1rem">{props.choices[0]}</Typography>
        <AntSwitch onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
        <Typography fontSize="1.1rem">{props.choices[1]}</Typography>
      </Stack>
    </FormGroup>
  );
}