import { useEffect, useState } from "react"
import styled from "styled-components"
// import moment from "moment-timezone";
import { NBATeamLogos } from "./teamlogos/NBATeamLogos";
import { MLBTeamLogos } from "./teamlogos/MLBTeamLogos";
import { NFLTeamLogos } from "./teamlogos/NFLTeamLogos";
//import apiWrapper from "../../helpers/ApiWrapper";
import LoadingSpinner from "../../Global/LoadingSpinner";
import { getOdds } from "../../helpers";
import { NHLTeamLogos } from "./teamlogos/NHLTeamLogos";


const Sports = (props) => {

    const [games, setGames] = useState([])
    const [loading, setLoading] = useState(true)
    let gamesDisp = false; 
    
    // const [drawExist, setDrawExist] = useState(false);
    
    function removeHours(date, hours) {
        const hoursToAdd = hours * 60 * 60 * 1000;
        date.setTime(date.getTime() - hoursToAdd);
        return date.toJSON();
      }

    const cleanDate = (apiDate) => {
        const cleanCurrDate = new Date().toJSON()
        const cleanApiDate = removeHours(new Date(apiDate), 5)
        return cleanApiDate.split("T")[0] === cleanCurrDate.split("T")[0]
    }

    useEffect(() => {
        console.log('Effect running'); // Log when the effect runs
        console.log('props.sportCode:', props.sportCode); // Log the value of props.sportCode
        console.log('props.market:', props.market); // Log the value of props.market
        
        setLoading(true); // Set loading to true before fetching data
        getOdds(props.sportCode, props.market)
            .then(data => {
                console.log("Fetched");
                // Set games data
                setGames(data);
                // Set loading to false after data is fetched
                setLoading(false);
            })
    }, [props.sportCode, props.market, props.oddsFormat]); // Dependency array
    

    const getTeamLogo = (teamName) => {
        switch (props.sportCode) {
            case 'basketball_nba':
                return NBATeamLogos[teamName];
            case 'baseball_mlb':
                return MLBTeamLogos[teamName];
            case 'americanfootball_nfl':
                return NFLTeamLogos[teamName];
            case 'icehockey_nhl':
                return NHLTeamLogos[teamName];
            default:
                return null;
        }
    };

    const cleanOdds = (odds) => {
        if (props.oddsFormat.toLowerCase() === "american"){
            if (odds >= 2){
                return "+" + Math.round((odds - 1)*100)
            } else return Math.round(-100/(odds - 1))
        } else return odds
    }

    // const processArbitrage = (homeOdds, awayOdds, drawOdds) => {
    //     if (drawExist) {
    //         return (
    //             Math.round(((1 / homeOdds + 1 / awayOdds + 1 / drawOdds) * 100) * 100) / 100
    //         )
    //     } else {
    //         return (
    //             Math.round(((1 / homeOdds + 1 / awayOdds) * 100) * 100) / 100
    //         )
    //     }
    // }

    return (
        
        (loading ? (<LoadingSpinner/>):(<SportWrap>
        <>
        {games.map ((game) => {
            return (
                cleanDate(game.start) ? (
                    <GameWrap>
                        <TeamWrap>
                            <HomeTeamWrap>
                                <TeamLogoWrap>
                                    {gamesDisp = true}
                                    {game.home !==undefined ? (
                                    <>
                                        <TeamLogo>
                                            <H2>{getTeamLogo(game.home.team)}</H2>
                                        </TeamLogo>
                                        <TeamName>
                                            <H2>{game.home.team}</H2>
                                        </TeamName>
                                    </>
                                    ): (
                                        <TotalsDisp>
                                            <H2>{getTeamLogo(game.home_team)}</H2>
                                            <H6> v </H6>
                                            <H2>{getTeamLogo(game.home_team)}</H2>
                                        </TotalsDisp>
                                    )}
                                </TeamLogoWrap>
                                <OddsDisplay>
                                    {game.home !== undefined ? (
                                        game.home.spread !== undefined ? (
                                            <>
                                                <Odds>{"Spread: " + game.home.spread}</Odds>
                                                <Odds>{"Odds: " + cleanOdds(game.home.odds)}</Odds>
                                            </>
                                        ) : (
                                            <>
                                                <Odds>{cleanOdds(game.home.odds)}</Odds>
                                            </>
                                        )
                                    ) : (
                                        <>
                                            <Odds>{"+" + game.total[0] + ": " + cleanOdds(game.over.odds)}</Odds>
                                        </>
                                    )}
                                </OddsDisplay>
                                <BookWrap>
                                    {game.home !== undefined ? (game.home.book) : (game.over.book)}
                                </BookWrap>
                            </HomeTeamWrap>
                            <AwayTeamWrap>
                            <TeamLogoWrap>
                                    {game.away !== undefined ? (
                                        <>
                                            <TeamLogo>
                                                <H2>{getTeamLogo(game.away.team)}</H2>
                                            </TeamLogo>
                                            <TeamName>
                                                <H2>{game.away.team}</H2>
                                            </TeamName>
                                        </>
                                    ):(
                                        <>
                                            <H5>{game.home_team + " / " + game.home_team}</H5>
                                        </>
                                    )}
                                </TeamLogoWrap>
                                <OddsDisplay>
                                    {game.away !== undefined ? (
                                            game.away.spread !== undefined ? (
                                                <>
                                                    <Odds>{"Spread: " + game.away.spread}</Odds>
                                                    <Odds>{"Odds: " + cleanOdds(game.away.odds)}</Odds>
                                                </>
                                            ) : (
                                                <>
                                                    <Odds>{cleanOdds(game.away.odds)}</Odds>
                                                </>
                                            )
                                        ) : (
                                            <>
                                                <Odds>{"+" + game.total[1] + ": " + cleanOdds(game.under.odds)}</Odds>
                                            </>
                                        )}
                                </OddsDisplay>
                                <BookWrap>
                                    {game.away !== undefined ? (game.away.book) : (game.under.book)}
                                </BookWrap>
                            </AwayTeamWrap>
                        </TeamWrap>
                    </GameWrap>) : (null)
            )
        })}
        {gamesDisp ? null : (
            <GameWrap>
                <NoGamesWrap>
                    <H2>There are no {props.sport} games today</H2>
                </NoGamesWrap>
            </GameWrap>
        )}
        </>
        </SportWrap>))
    )
}

const Odds = styled.div `
display: flex;
justify-content: space-around;
align-items: center;
min-width: 100px;
`

const H2 = styled.h2 `
color: #fff;
font-weight: 100;
text-align: center;
margin: 0;
`
const H5 = styled.h5`
color: #fff;
font-weight:100;
margin: 0;
`

const H6 = styled.h6`
color: #fff;
font-weight:100;
margin: 0;
`

const TeamLogoWrap = styled.div `
display: flex;
align-items: center;
justify-content: space-between;
width: 50%;
padding: 3px;
`

const BookWrap = styled.div`
display: flex;
justify-content: space-around;
align-items: center;
width: 30%;
`

const AwayTeamWrap = styled.div`
display: flex;
padding: 5px;
width: 100%;
justify-content: space-between;
`

const HomeTeamWrap = styled.div `
display: flex;
padding: 5px;
border-bottom: 1px solid white;
width: 100%;
justify-content: space-between;
`

const TeamWrap = styled.div `
display: grid;
grid-template-rows: 1fr 1fr;
flex-direction: column;
width: 80%;
margin: auto;
`

const GameWrap = styled.div `
display: flex;
padding: 20px;
width: 80%;
min-width: 470px;
background-color: #29282B;
margin: 7px auto;
border-radius: 5px;
color: #fff;
font-weight: 100;
justify-content: space-between;
box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`

export const SportWrap = styled.div `
display: flex;
flex-direction: column;
width: 80%;
height: 80%;
min-width: 470px;
justify-content: flex-start;
align-items: center;
overflow: scroll;
margin: auto;
`

const NoGamesWrap = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
`

const OddsDisplay = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`
const TotalsDisp = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`

const TeamLogo = styled.div`
align-items: center;
`

const TeamName = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: space-around;
`

export default Sports