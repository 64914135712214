import styled from 'styled-components';
import { FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import PropTypes from 'prop-types';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, Key } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
//import apiWrapper from '../../helpers/ApiWrapper';
import { useMorph } from 'react-morph';
import CloseButton from 'react-bootstrap/CloseButton';
import { payFunc, updateCustomerID } from '../../helpers';

const DUBSGREEN = "#20b766"

export interface pricingType {
    data: any;
    price:any;
    duration?: any;
    background?: any;
    shadow?: '#a0c5fa';
    buttonContent: any;
    currency?: '$';
    subTitle: any;
    headerText:any;
    plan: string
}



const Pricing = (
    props: pricingType
) => {
  const navigate = useNavigate()
  const morph = useMorph()
  const [showPayments, setShowPayment] = useState(false)

  let token:any =  localStorage.getItem('dubsToken')
  const getPaymentUrl = async (value: string) =>{
    const payObj = {
      params: {
        plan: props.plan,
        type: value
      }
    }
    //@ts-ignore
    const nextUrl = await payFunc(payObj) //apiWrapper('pay', payObj)
    if (nextUrl !== "failed"){
      if (nextUrl.customer){
        updateCustomerID(nextUrl.customer) //apiWrapper('update-customer-id', {data:{customer_id: nextUrl.customer}})
      }
      window.location.replace(nextUrl.url)
    }
  }
  
  const handleSubmit = (e: any) =>{
    if (token){
      getPaymentUrl(e.target.value)
    } else {
      navigate(`/login?plan=${props.plan}&type=${e.target.value}`)
    }
  }


  return (
    // @ts-ignore
    <MainContainer shadow={props.shadow}>
       {/** @ts-ignore */}
      <Header background={props.background}>{props.headerText}</Header>
      {props.price !== undefined && (
        <PricingContainer>
          <PriceContainer>
            <CurrencyContainer>
              <span>{props.currency}</span>
            </CurrencyContainer>
            <Price>
              <span>{props.price}</span>
            </Price>
            {props.price > 0 && (
              <Duration>
                <span> {props.duration ? `/ ${props.duration}` : null}</span>
              </Duration>
            )}
          </PriceContainer>
          {props.subTitle && (
            <SubTitle>
              <h4>{props.subTitle}</h4>
            </SubTitle>
          )}
        </PricingContainer>
      )}
      {props.buttonContent && (
        <ButtonContainer>
          {showPayments ? (
            <>
              <CloseButtonDiv>
                <CloseButton {...morph} variant="white" onClick={() => setShowPayment(false)} />
              </CloseButtonDiv>
              <PayButton
              {...morph}
              onClick={(e) => handleSubmit(e)}
              value={"stripe"}
              >
            Debit/Credit
          </PayButton>
          <PayButton
              {...morph}
              onClick={(e) => handleSubmit(e)}
              value={"paypal"}
              >
            PayPal
          </PayButton>
            </>
          ):(
            <Button
              {...morph}
              onClick={() => setShowPayment(true)}
              value={"stripe"}
              >
            {props.buttonContent}
          </Button>
          )}
        </ButtonContainer>
      )}
      {props.data && (
        <DataContainer>
          <ul>
            {props.data.map((dt: { value: any; text: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
              <li key={index}>
                {dt.value ? (
                  <FaCheck className="true" />
                ) : (
                  <ImCross className="false" />
                )}
                {dt.text}
              </li>
            ))}
          </ul>
        </DataContainer>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
  font-family: 'Raleway', sans-serif;
  min-width: 45vh;
  @media (max-width: 767px) {
    min-width: 350px;
  }
  padding: 5vh;
  height: max-content;
  background-color: #000;
  display: block;
  flex-direction: column;
  margin: 5vh;
  color: #20b766;
  margin-bottom: 0.4rem;
  box-sizing:border-box;
  display:table-cell;
  border-radius: 0.3rem;
  z-index: -10;
`;

const CloseButtonDiv = styled.div`
  margin: 5px;
`

const Header = styled.div`
  border-radius: 0.3rem;
  margin: 0.6rem;
  height: 3.5rem;
  background-color: #20b766;
  color: black;
  background-image: ${
    // @ts-ignore
    (props) => props.background
};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  font-weight: 300;
`;

const PricingContainer = styled.div`
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;

const PriceContainer = styled.div`
  display: flex;
`;

const CurrencyContainer = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.2rem;
`;

const Price = styled.div`
  span {
    font-size: 3rem;
  }
`;

const Duration = styled.div`
  margin-top: 2rem;
`;

const SubTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  margin: 0.2rem 0 0.5rem 0;
`;


const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const PayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0.3rem;
  border: 0.1rem solid ${DUBSGREEN};
  width: 90%;
  height: 3rem;
  margin: 0.3rem;
  background-color: ${DUBSGREEN};
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: ${DUBSGREEN};
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0.3rem;
  border: 0.1rem solid ${DUBSGREEN};
  width: 90%;
  color: black;
  height: 3rem;
  background-color: ${DUBSGREEN};
  font-size: 1.2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: transparent;
    color: ${DUBSGREEN};
  }
`;

const DataContainer = styled.div`
  ul {
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      .true {
        color: #34f034;
        font-size: 1rem;
      }
      .false {
        color: #f54343;
      }
      svg {
        margin-right: 0.5rem;
        font-size: 0.8rem;
      }
      margin-bottom: 1rem;
    }
  }
`;

Pricing.propTypes = {
  data: PropTypes.array.isRequired,
  price: PropTypes.number.isRequired,
  duration: PropTypes.oneOf(['y', 'm']).isRequired,
  buttonContent: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default Pricing;
