import React from "react";
import PropTypes from "prop-types";

const COL = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 215.644 176.21"
    >
      <path
        d="M215.544 143.766l-4.485 8.158c-10.608 16.317-27.325 23.45-43.23 24.063s-20.39-6.122-24.263-8.97c-3.873-2.86-9.383-16.106-9.383-16.106l.11-3.605a190.484 190.484 0 0 1-9.27 3.484c-14.08 4.874-28.036 7.88-41.203 9.148l-3.27 8.314S22.627 171.712 0 176.21l18.076-29.94c-5.765-4.574-9.995-10.184-12.265-16.762-10.506-30.33 24.11-69.94 77.633-89.43L107.873 0l11.007 17.13c15.704 2.86 18.353 12.142 18.353 12.142 33.958-1.08 61.048 10.05 68.316 31.064 4.596 13.267.544 28.315-9.95 42.684 0 0 19.532 11.987 20.044 40.235l-.1.51z"
        fill="#236192"
      />
      <path
        d="M165.314 90.743L137.71 46.69c1.36-2.45 1.36-7.18 1.36-7.18 29.994 1.85 52.922 13.87 57.608 32.98 2.204 8.98.1 18.453-5.42 27.568.01.012-9.772-6.588-25.944-9.315z"
        fill="#FFF"
      />
      <path
        d="M203.657 132.814c-2.248 3.083-4.597 5.943-4.597 5.943-.68.857-1.914 2.237-3.094 3.45l-.968 11.642-1.88-.29c-.513-1.124-2.784-4.686-3.707-5.487-12.832 9.66-25.776 10.65-25.776 10.65-14.636 2.728-24.163-3.06-24.163-3.06 1.715 4.352 6.456 9.928 6.456 9.928 6.945 8.438 18.943 7.97 18.943 7.97 33.133-.646 46.088-25.81 46.088-25.81l-2.204-.11c-.144-5.03-4.474-13.613-5.097-14.826z"
        fill="#111"
      />
      <path
        d="M175.42 162.787c-6.522 1.914-23.863 5.888-31.932-3.172 0 0 9.85 8.17 33.69.267l-1.758 2.905z"
        fill="#A2AAAD"
      />
      <path
        d="M180.807 164.713c-23.863 7.913-33.713-.267-33.713-.267 10.05 11.286 34.48 2.36 34.48 2.36 3.718-1.146 7.49-3.283 9.64-4.608l1.235-3.24c-3.14 2.817-11.643 5.755-11.643 5.755z"
        fill="#A2AAAD"
      />
      <path
        d="M70.965 54.648c-20.257 12.99-34.904 29.36-37.54 55.873 0 0 1.268-1.18 2.17-1.813.088 7.324 4.373 24.653 25.175 25.477 20.802.824 30.185-5.51 37.12-6.734 6.933-1.224 20.8-2.86 32.22-1.224 11.42 1.636 32.077 6.21 39.49 25.532-4.62 1.58-14.203 3.016-19.512 2.404-5.298-.612-11.83-3.06-12.644-9.182-.412 6.934 5.098 10.395 11.22 11.008s13.244.067 22.838-2.315c2.048-2.794 2.048-6.845 2.148-8.793 2.137 1.514 3.773 4.374 4.174 5.8 1.836-2.86-.48-9.183-1.77-11.832 2.782 1.836 6.344 7.58 6.6 8.993l1.48-1.847c-.2-3.46-6.233-12.232-7.502-13.868l-5.276 3.306c-6.4-9.025-15.983-14.323-25.154-16.972-9.17-2.65-20.28-4.04-32.778-3.24 7.08-3.282 23.395-4.707 32.978-2.47 9.583 2.248 18.21 5.754 27.157 16.44l4.763-3.262c4.986 4.352 14.948 18.665 16.662 27.892l.88-.1s.422-18.13-.123-24.664c1.49 1.224 3.428 3.183 3.884 4.174 1.692-2.137.467-7.847-.078-10.15 2.994 3.127 9.928 13.533 11.353 24.574 1.147-3.773.747-7.858.613-9.35-.134-1.49-.946-5.297-3.395-11.552-2.45-6.255-5.71-13.456-13.868-20.257-8.158-6.8-17.663-12.91-35.215-13.868-14.96-.812-37.52 7.613-43.507 10.062-5.977 2.45-22.16 9.516-33.167 11.152s-16.65 2.292-20.29-1.57l-1.08 1.68c-4.596-1.468-9.905-9.08-10.584-14.112-.657-5.008-1.202-10.318 4.107-21.592 5.3-11.288 14.27-20.938 16.452-23.598z"
        fill="#FFF"
      />
      <path
        d="M48.66 80.37c-4.074 7.112-9.783 22.75-4.485 33.357s21.347 11.965 31.542 9.928c10.195-2.037 22.427-6.667 27.602-8.837 5.163-2.17 15.46-6.934 26.577-8.77s19.578-1.525 28.448 2.65c8.87 4.184 14.19 7.01 21.626 14.77l3.017-1.57 6.166 6.5 1.324-9.828 3.74-5.72c-1.39-3.64-8.56-14.158-23.284-16.44 4.552 1.992 13.835 5.654 19.8 16.317l-2.65 4.63-.333 4.797-4.296-4.63-2.27 1.425c-3.35-4.264-10.964-10.786-21.838-13.913-10.874-3.128-24.208-3.95-36.707-.545-10.474 2.86-16.863 5.71-19.846 7.068-2.994 1.358-8.292 3.94-15.36 5.988-7.067 2.037-14.134 3.528-22.704 2.315-8.57-1.224-16.038-5.298-18.086-14.814s-.722-13.973 2.016-24.68z"
        fill="#236192"
      />
      <path
        d="M89.93 143.566l-2.45 6.522c9.584.412 31.51-3.873 44.766-8.46-5.41-3.16-19.277-6.02-42.316 1.938z"
        fill="#FFF"
      />
      <path
        d="M55.127 91.912L107.972 5.81l53.635 84.532c-3.36-.412-9.216 0-12.065.48-2.86.478-9.25 1.835-11.008 2.314-1.77.48-10.874 3.06-13.935 4.074-3.062 1.013-11.765 4.074-14.48 5.098-2.728 1.013-9.528 3.873-12.11 4.753-2.582.88-7.546 2.583-12.577 3.607-5.03 1.024-9.783 1.97-14.747 1.49-4.964-.478-7.524-1.924-9.716-3.94-1.636-1.49-4.474-5.13-5.298-9.927-.69-3.94-.6-5.477-.545-6.378z"
        fill="#A2AAAD"
      />
      <path
        d="M108.028 11.475l49.807 78.7c-2.994.022-6.344.312-8.292.646-2.86.48-9.25 1.837-11.008 2.316-1.77.48-10.874 3.06-13.935 4.074s-11.764 4.074-14.48 5.098c-2.727 1.013-9.527 3.873-12.11 4.753-2.58.88-7.545 2.583-12.576 3.607-5.03 1.024-9.783 1.97-14.747 1.49-4.964-.478-7.524-1.924-9.716-3.94-1.635-1.49-4.473-5.13-5.297-9.927-.067-.378-.134-.735-.178-1.068l52.533-85.747zm-35.45 98.022c12.934 1.636 41.683-11.753 41.683-11.753 26.59-10.262 35.406-9.27 35.406-9.27-9.772-3.707-34.614 6.454-34.614 6.454l-6.69-21.47-10.806 28.727c-18.778 6.867-24.977 7.312-24.977 7.312z"
        fill="#6F263D"
      />
      <path
        d="M101.35 100.448l10.663-4.396-4.085-12.966-6.578 17.362z"
        fill="#236192"
      />
      <path
        d="M25.666 134.016c-5.398-4.74-9.115-10.44-10.718-16.95C9.06 93.045 33.89 65.633 72.59 50.507c0 0-30.04 13.6-42.828 43.23-6 13.91-2.994 28.547.134 33.578l-4.23 6.7z"
        fill="#FFF"
      />
      <path
        d="M74.126 50.675l.913-1.425c-7.135 3.06-16.83 9.883-19.99 12.432-3.16 2.55-9.282 7.746-13.556 12.844s-8.258 11.43-10.807 17.04c-2.55 5.61-4.185 11.008-4.586 20.39s5.51 18.454 5.51 18.454l-25.8 42.005s7.746-1.224 9.895-1.525c2.137-.3 10.607-1.324 14.168-1.736 3.573-.412 15.393-1.525 19.99-1.836 4.585-.3 17.74-1.425 28.547-1.836l9.483-24.976c4.185-1.024 7.034-2.55 14.068-3.973 7.035-1.425 14.17-1.937 25.288-.1 11.12 1.836 21.715 8.258 24.063 10.395-2.648-4.586-8.257-8.87-17.228-11.83-8.97-2.962-18.765-2.962-24.063-2.75-5.3.2-13.87 2.137-18.555 3.46s-9.282 3.462-14.992 4.687c-5.71 1.224-11.22 2.137-16.83 1.636s-13.967-1.123-21.613-8.57c-7.646-7.445-9.483-16.316-8.67-26.61.812-10.285 8.058-24.465 16.617-34.148s22.127-19.067 28.15-22.027z"
        fill="#A2AAAD"
      />
      <path
        d="M84.555 141.318l-8.036 21.214c-37.632 2.237-64.488 5.832-64.488 5.832l21.603-35.293c1.848 2.65 7.045 7.547 13.055 9.684s9.895 2.65 15.493 2.348c5.62-.312 12.622-1.124 22.372-3.784z"
        fill="#6F263D"
      />
      <path
        d="M135.485 42.35c5.487-25.488-27.647-25.755-27.647-25.755l-1.503 7.257-5.098 1.57L98.1 36.795l-4.107 3.34-6.01 9.805c-.01-.01 37.83-28.237 47.502-7.59z"
        fill="#FFF"
      />
      <path
        d="M103.008 33.245l.523-2.092s19.478-6.41 27.715 1.436c0 .01-8.626-6.657-28.237.655z"
        fill="#236192"
      />
      <path
        d="M96.675 40.035s27.647-17.808 38.098-.946c0 0-5.754-15.16-40.135 4.05l2.037-3.105z"
        fill="#A2AAAD"
      />
    </svg>
  );
};

COL.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

COL.defaultProps = {
  size: "100"
};

export default COL;
