import { useState } from 'react';

const Button = ({ onClick, text, textSize = '16px', disabled = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    backgroundColor: isHovered || disabled ? "#333333" : "#1ed27f",
    color: isHovered ? "#1ed27f":'white', // You can change the text color as needed
    padding: '0px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '50px',
    fontSize: textSize,
    fontFamily: 'Montserrat-Bold',
    width: '100%',
    
  };

  return (
    <button
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Button;


