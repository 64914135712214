import React from 'react';
import {
  ANA,
  ARI,
  BOS,
  BUF,
  CAR,
  CBJ,
  CGY,
  CHI,
  COL,
  DAL,
  DET,
  EDM,
  FLA,
  LAK,
  MIN,
  MTL,
  NHL,
  NJD,
  NSH,
  NYI,
  NYR,
  OTT,
  PHI,
  PIT,
  SJS,
  STL,
  TBL,
  TOR,
  VAN,
  VGK,
  WPG,
  WSH,
} from './react-nhl-logos/src';

export const NHLTeamLogos = {
  "Anaheim Ducks": <ANA size={40} />,
  "Arizona Coyotes": <ARI size={40} />,
  "Boston Bruins": <BOS size={40} />,
  "Buffalo Sabres": <BUF size={40} />,
  "Carolina Hurricanes": <CAR size={40} />,
  "Columbus Blue Jackets": <CBJ size={40} />,
  "Calgary Flames": <CGY size={40} />,
  "Chicago Blackhawks": <CHI size={40} />,
  "Colorado Avalanche": <COL size={40} />,
  "Dallas Stars": <DAL size={40} />,
  "Detroit Red Wings": <DET size={40} />,
  "Edmonton Oilers": <EDM size={40} />,
  "Florida Panthers": <FLA size={40} />,
  "Los Angeles Kings": <LAK size={40} />,
  "Minnesota Wild": <MIN size={40} />,
  "Montreal Canadiens": <MTL size={40} />,
  "Nashville Predators": <NSH size={40} />,
  "New Jersey Devils": <NJD size={40} />,
  "New York Islanders": <NYI size={40} />,
  "New York Rangers": <NYR size={40} />,
  "Ottawa Senators": <OTT size={40} />,
  "Philadelphia Flyers": <PHI size={40} />,
  "Pittsburgh Penguins": <PIT size={40} />,
  "San Jose Sharks": <SJS size={40} />,
  "St. Louis Blues": <STL size={40} />,
  "Tampa Bay Lightning": <TBL size={40} />,
  "Toronto Maple Leafs": <TOR size={40} />,
  "Vancouver Canucks": <VAN size={40} />,
  "Vegas Golden Knights": <VGK size={40} />,
  "Winnipeg Jets": <WPG size={40} />,
  "Washington Capitals": <WSH size={40} />,
  "NHL": <NHL size={40} />,
};