import { Link } from "react-router-dom"
import styled from "styled-components"
import StickyScroll from "./StickyScroll"
import Footer from "../Global Components/Footer"


const Home = () => {
    return (
        <>
        <StickyScroll/>
        </>
    )
}


const H1 = styled.h1 `
    color: #fff;
`
export default Home