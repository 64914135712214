import { PricingTable } from 'react-pricing-table';
import '../../css/plan.css'
import { Row } from 'antd';

import styled from 'styled-components';
import DubsCard from './DubsCard';

const GUARANTEE = false;

const PlanCardsV2 = () => {

  const first  = GUARANTEE ? "guarantee": "single";
  const second = "premium";
  const third = "monthly"

  return (
    <MainContainer>
      <StyledRow style={{ marginTop: 15 }}>
        <StyledDiv>
        <PricingTable className="PricingTable">
          <DubsCard plan={first}/>
          <DubsCard plan={second} />
          <DubsCard plan={third} />
        </PricingTable>
        </StyledDiv>
      </StyledRow>
    </MainContainer>
  );
};

export default PlanCardsV2;

const StyledDiv = styled.div`
display: flex;
justify-content: center;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing:border-box;

`;

const StyledRow = styled(Row) `
justify-content: center;

`

