// Importing useState and useEffect from React
import { useState, useEffect } from 'react';
import '../css/main.css'
import styled from 'styled-components';
import { DubsSubHeader } from '../../../Global/StaticElements';
import Button from '../../../Global/Button';

// TypeScript interface for Modal Props
interface ModalProps {
    show: boolean;
    onClose: () => void;
    onSubmit: (reason: string) => void;
}

// Modal component
const CancelModal = ({ show, onClose, onSubmit }: ModalProps) => {
    const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
    const [otherReason, setOtherReason] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }, [show]);

    if (!show) {
        return null;
    }

    const reasons = [
        "Poor customer service",
        "Not enough betting options",
        "Concerns about safety and security",
        "Better odds elsewhere",
        "Other"
    ];

    const handleCheckboxChange = (reason: string) => {
        if (reason === "Other") {
            setIsOtherSelected(!isOtherSelected);
            if (isOtherSelected) {
                setOtherReason('');
            }
        } else {
            setSelectedReasons(prev => 
                prev.includes(reason) 
                ? prev.filter(r => r !== reason) 
                : [...prev, reason]
            );
        }
    };

    return (
        <ModalBody>
            <ModalContent>
                <Close onClick={onClose}>&times;</Close>
                <DubsSubHeader>Why did you cancel your subscription?</DubsSubHeader>
                <form>
                {reasons.map(reason => (
                        <div key={reason}>
                            <input 
                                type="checkbox" 
                                id={reason} 
                                name={reason} 
                                onChange={() => handleCheckboxChange(reason)}
                                checked={reason === "Other" ? isOtherSelected : selectedReasons.includes(reason)}
                            />
                            <label htmlFor={reason}>{reason}</label>
                        </div>
                    ))}
                    {isOtherSelected && (
                        <ModalText
                            className="modal-textarea"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            placeholder="Please specify"
                        />
                    )}
                    <Button
                    text="Cancel"
                    onClick={() => onSubmit(selectedReasons[0] || otherReason)}
                    disabled={!selectedReasons.length && !otherReason}
                    />
                </form>
            </ModalContent>
        </ModalBody>
    );
};

export default CancelModal;

const ModalBody = styled.div`
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
    background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`

const Close = styled.span`
    float: right;
  font-size: 28px;
  cursor: pointer;
`
const ModalText = styled.textarea`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
`


