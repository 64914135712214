import React from "react";
import PropTypes from "prop-types";

const STL = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 223.96002 179.01444"
    >
      <path
        d="M223.96,0H38.89261c12.24146,19.10752,16.35206,25.43154,5.91746,25.11532L29.40661,0H0L66.85374,109.26972c-.90343-.04517-1.80686-.09034-2.71029-.09034-27.41906,0-48.8755,15.31312-48.8755,34.91753s21.45644,34.91753,48.8755,34.91753,48.87549-15.31312,48.87549-34.91753a27.12657,27.12657,0,0,0-4.06543-14.229L93.9566,105.385s12.06078,2.43926,21.09507-1.76168L110.7152,98.022S129.1,99.73854,146.03927,94.67934L140.3025,88.5812A61.79214,61.79214,0,0,0,183.93812,65.0017h-1.76168s13.32558-6.41435,18.92682-20.64335h-1.76168s8.5374-6.324,11.42837-19.37855h-2.48442S221.837,15.40346,223.96,0Z"
        fill="#002654"
      />
      <path
        d="M219.39771,3.20717H44.90043c14.27418,21.68229,10.07323,25.16049-1.8972,25.16049L27.59975,3.20717H5.73677l67.16993,109.7666a69.63,69.63,0,0,0-8.76326-.58723c-25.61221,0-45.66833,13.9128-45.66833,31.71035,0,17.75238,20.05612,31.71035,45.66833,31.71035s45.66833-13.958,45.66833-31.71035a23.67766,23.67766,0,0,0-3.65889-12.55766L87.13571,100.50646s13.23523,4.33645,22.49538,1.44548L103.804,94.40831s21.863,1.80686,36.45335-1.12929l-6.95641-7.40811s32.56861-.3162,44.67455-20.41749h-5.55609s15.35829-2.12306,23.0826-18.701h-5.64643s13.19005-6.09815,16.75861-19.92061h-6.14332S214.203,17.75238,219.39771,3.20717Z"
        fill="#fff"
      />
      <path
        d="M215.73881,5.51092H48.92068C58.81322,20.553,60.66525,31.62,41.69325,30.58107L26.335,5.51092H9.757L77.469,116.09061a63.03158,63.03158,0,0,0-13.32558-1.44548c-23.94086,0-43.40975,13.23523-43.40975,29.45178s19.46889,29.45179,43.40975,29.45179,43.40976-13.23523,43.40976-29.45178a21.69439,21.69439,0,0,0-3.29752-11.338L82.16685,96.71206s13.9128,4.87851,23.53432,3.83958a88.68759,88.68759,0,0,1-6.324-8.76326,193.6718,193.6718,0,0,0,36.49852.13551l-7.76948-8.2212h2.03271c33.51721,0,43.59045-17.16514,43.59045-17.16514h-9.12464s22.63089-5.73678,27.46423-18.701h-8.40188s17.93306-8.80843,19.69474-18.74615H193.1079C193.15309,29.09041,210.99582,19.01718,215.73881,5.51092Z"
        fill="#fcb514"
      />
      <path
        d="M211.85406,7.905H53.212c13.14489,21.45644-.04517,26.335-12.919,24.97981L24.97981,7.905H14.04832L82.66374,119.97535a58.74186,58.74186,0,0,0-18.52029-2.93614c-22.63089,0-40.9705,12.10595-40.9705,27.0577s18.33961,27.0577,40.9705,27.0577c22.67607,0,41.01567-12.10595,41.01567-27.0577a18.85084,18.85084,0,0,0-2.98132-10.07324L76.74628,92.46595s16.4424,5.6916,24.03121,5.6916a48.00922,48.00922,0,0,1-5.55609-9.12463s19.69475,2.12306,35.45958.85826l-8.58257-9.03429a51.67081,51.67081,0,0,0,8.04052.45171c27.73526,0,38.35056-12.3318,38.35056-12.3318H86.05161L84.832,66.98925h68.43474S181.1375,61.659,187.868,50.23065h-113.29l-1.17447-1.94237H177.65928s19.42372-9.35049,22.17918-16.75861H63.10451L61.93,29.58729H187.597C187.597,29.54212,205.89144,20.01095,211.85406,7.905Z"
        fill="#002f87"
      />
    </svg>
  );
};

STL.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

STL.defaultProps = {
  size: "100"
};

export default STL;
