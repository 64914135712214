import { ButtonWrap, Text, TextWrap } from '../../Global/StaticElements';
//import apiWrapper from "../../helpers/ApiWrapper";
import { useState } from "react";
import { sendUserEmail } from '../../helpers';

export default function UnsubscribeEmail(){
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const [ email, setEmail ] = useState("");

    const handleChange = (event: any) => {
		setEmail(event.target.value);
	  };

    const handleSubmit = async (e: any) => {
		e.preventDefault()
        const result = await sendUserEmail("unsubscribe", email) //apiWrapper("send-user-email", {data:{funct:"unsubscribe", email: email}})
        if (result !== "failed"){
            setSuccess(true)
        } else {
            setEmail("")
            setError(true)

        }
    }

    return (
            error ? (
                <>
                    <TextWrap>
                        <Text>
                            Something went wrong, please try again
                            <ButtonWrap>
                            <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" value={email} onChange={handleChange}/>
                                <span className="focus-input100"></span>
                                {email ? <span className="label-input100"></span> : <span className="label-input100">Email</span> }
                            </div>
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" onSubmit={(e) => handleSubmit(e)}>
                                                Enter
                                    </button>
                                </div>
                            </form>
                        </ButtonWrap>
                        </Text>
                        
                    </TextWrap>
                </>
            ) : (success ? (
                <>
                    <TextWrap>
                        <Text>
                            You've Successfully Unsubscribed
                        </Text>
                    </TextWrap>
                </>
            ):(
                <>
                    <TextWrap>
                        <Text>
                            We're sorry to see you go! Enter your email below to unsubscribe from future emails
                            <ButtonWrap>
                            <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                                <input className="input100" type="text" name="email" value={email} onChange={handleChange}/>
                                <span className="focus-input100"></span>
                                {email ? <span className="label-input100"></span> : <span className="label-input100">Email</span> }
                            </div>
                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn" onSubmit={(e) => handleSubmit(e)}>
                                                Enter
                                    </button>
                                </div>
                            </form>
                        </ButtonWrap>
                        </Text>
                        
                    </TextWrap>
                </>
                )
            )
        );
        
}
